import React, { useEffect, useMemo } from 'react';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import AggregatorGraph from './AggregatorGraph';
import styles from './AggregatorRentals.module.css';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import Loader from 'components/Loader/Loader';
import { useAggregatorReport } from 'contexts/AggregatorReportContex';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

type AggregatorRentalsProps = {
    filters: { monthYear: MonthYear['value'] };
    handleDateFilterSelected: (value: string) => void;
    spotPk: number;
};

function AggregatorRentals({ filters, handleDateFilterSelected, spotPk }: AggregatorRentalsProps) {
    const monthYearUtil = new MonthYearUtil();

    const { reports, loading, errorMessage, getAggregatorReport } = useAggregatorReport();

    useEffect(() => {
        if (!reports[filters.monthYear]) {
            getAggregatorReport(filters.monthYear, spotPk);
        }
    }, [spotPk, getAggregatorReport, filters.monthYear, reports]);

    const currentReport = useMemo(() => {
        return reports[filters.monthYear];
    }, [reports, filters.monthYear]);

    const [rentals, total] = useMemo(() => {
        if (currentReport) return [currentReport.aggregator_rentals.rentals, currentReport.aggregator_rentals.total];
        else return [null, null];
    }, [currentReport]);

    return (
        <>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            {currentReport && rentals && total && !loading ? (
                <>
                    <AGSimpleSelect
                        placeholder="Select month and year"
                        selected={filters.monthYear}
                        onSelect={handleDateFilterSelected}
                        options={monthYearUtil.monthYearsForSelect}
                        icon={<Lines />}
                        maxHeigh="420px"
                    />

                    <div className={styles.graphWrapper}>
                        <h3>Active Rentals by Source</h3>
                        <AggregatorGraph report={rentals} />
                    </div>

                    <AGTAbleWrapper>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>SpotHero</th>
                                <th>CheapAirportParking</th>
                                <th>AirportParking</th>
                                <th>ParkWhiz</th>
                                <th>Parkway</th>
                                <th>Noson</th>
                                <th>AirGarage</th>
                                <th>Total Rentals</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rentals.map((report) => {
                                return (
                                    <tr>
                                        <td>{report.date}</td>
                                        <td>{report.spothero}</td>
                                        <td>{report.cheap_airport}</td>
                                        <td>{report.airport_parking}</td>
                                        <td>{report.parkwhiz}</td>
                                        <td>{report.parkway}</td>
                                        <td>{report.noson}</td>
                                        <td>{report.airgarage}</td>
                                        <td>{report.total_rentals}</td>
                                    </tr>
                                );
                            })}
                            {total && (
                                <tr>
                                    <td>{total.date}</td>
                                    <td>{total.spothero}</td>
                                    <td>{total.cheap_airport}</td>
                                    <td>{total.airport_parking}</td>
                                    <td>{total.parkwhiz}</td>
                                    <td>{total.parkway}</td>
                                    <td>{total.noson}</td>
                                    <td>{total.airgarage}</td>
                                    <td>{total.total_rentals}</td>
                                </tr>
                            )}
                        </tbody>
                    </AGTAbleWrapper>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default AggregatorRentals;
