import { PagedResponse } from 'types/Pagination';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { Payout, PayoutAccountInfo } from 'types/Payouts';
import cache from './cache';

export type AccountInfoPayload = {
    account_number?: string;
    routing_number?: string;
    ein?: string;
    name?: string;
    dob?: string;
};

export const PAYOUT_PAGE_SIZE = 15;

type PayoutsClientParams = HTTPClientParameters;

class PayoutsClient extends HTTPClient {
    constructor(params: PayoutsClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    @cache(5 * 60000) // 5min of cache
    getPayouts(orgPk: number, page: number): Promise<PagedResponse<Payout>[]> {
        return this.get(`transactions/payouts/${orgPk}?page=${page}`);
    }

    getPayoutAccountInfo(orgPk: number): Promise<PayoutAccountInfo> {
        return this.get(`payouts?organization=${orgPk}`);
    }

    updatePayoutAccountInfo(
        payoutInfo: AccountInfoPayload & { organization: number }
    ): Promise<{ message: string; result: string }> {
        return this.post(`payouts`, payoutInfo);
    }
}

export default PayoutsClient;
