export function dobFormatter(oldValue: string, newValue: string) {
    // Return without formatting if user is deleting
    if (oldValue.length > newValue.length) {
        return newValue;
    }

    return (
        newValue
            // Handle month
            .replace(
                /^([1-9]\/|[2-9])$/g,
                '0$1/' // 3 > 03/
            )
            .replace(
                /^(0[1-9]|1[0-2])$/g,
                '$1/' // 11 > 11/
            )
            .replace(
                /^([0-1])([3-9])$/g,
                '0$1/$2' // 13 > 01/3
            )
            .replace(
                /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
                '$1/$2' // 131 > 01/31/
            )
            // Handle day
            .replace(
                /^([0-9]{2})(\/)([3])([2-9])$/g,
                '$1/0$3/$4' // 13/32 > 01/03/2
            )
            .replace(
                /^([0-9]{2})(\/)([1-9]\/|[4-9])$/g,
                '$1/0$3' // 11/4 > 11/04
            )
            // Add second /
            .replace(
                /^([0-9]{2})(\/)([0-9]{2})$/g,
                '$1/$3/' // 11/02 > 11/02/
            )
            // Handle Year
            .replace(
                /^([0-9]{2})(\/)([0-9]{2})(\/)([1-9]\/|[3-9])$/g,
                '$1/$3/19$5' // 12/11/7 -> 12/11/197
            )
            .replace(
                /[^\d/]|^[/]*$/g,
                '' // To allow only digits and `/`
            )
            .replace(
                /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})([0-9/])$/g,
                '$1' // Delimit length
            )
            .replace(
                /\/{2,}/g,
                '/' // Replace all duplicated "/"
            )
    );
}

export function dobValidator(value: string) {
    const validator = /^[0-9]{2}\/[0-9]{2}\/[1-9]{4}$/g;
    return validator.test(value);
}
