import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { payoutsClient } from 'clients';
import unauthorized from '../unauthorized';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import payoutsReducer, { PayoutsState, initialPayoutsState } from '../PayoutsContext/reducer';
import {
    PAYOUTS_REQUESTED,
    PAYOUTS_SUCCESS,
    PAYOUTS_FAILED,
    ADD_PAYOUT_ACCOUNT_INFO_FAILURE,
    ADD_PAYOUT_ACCOUNT_INFO_REQUESTED,
    PAYOUT_ACCOUNT_INFO_FAILED,
    PAYOUT_ACCOUNT_INFO_SUCCESS,
    PAYOUT_ACCOUNT_INFO_REQUESTED,
} from './constants';
import { AccountInfoPayload } from 'clients/PayoutsClient';
import { HTTPError } from 'clients/HTTPClient';

interface PayoutsActions {
    getPayouts: (orgPk: number, page?: number) => Promise<void>;
    getPayoutAccountInfo: (orgPk: number) => Promise<void>;
    addPayoutAccountInfo: (orgPk: number, payoutData: AccountInfoPayload) => Promise<void>;
}

interface PayoutsContextType extends PayoutsState, PayoutsActions {}

export const PayoutsContext = createContext<PayoutsContextType | null>(null);

export const PayoutsProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(payoutsReducer, { ...initialPayoutsState }, [], [unauthorized]);

    const getPayouts = useCallback(
        async (orgPk: number, page = 1) => {
            dispatch({ type: PAYOUTS_REQUESTED });

            try {
                const payouts = await payoutsClient.getPayouts(orgPk, page);

                dispatch({ type: PAYOUTS_SUCCESS, payload: { payouts } });
            } catch (error) {
                dispatch({
                    type: PAYOUTS_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch payouts' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getPayoutAccountInfo = useCallback(
        async (orgPk: number) => {
            dispatch({ type: PAYOUT_ACCOUNT_INFO_REQUESTED });

            try {
                const payoutAccountInfo = await payoutsClient.getPayoutAccountInfo(orgPk);

                dispatch({ type: PAYOUT_ACCOUNT_INFO_SUCCESS, payload: { payoutAccountInfo } });
            } catch (error) {
                dispatch({
                    type: PAYOUT_ACCOUNT_INFO_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch payout account info' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addPayoutAccountInfo = useCallback(
        async (orgPk: number, payoutData: AccountInfoPayload) => {
            dispatch({ type: ADD_PAYOUT_ACCOUNT_INFO_REQUESTED });
            try {
                const data = { ...payoutData, organization: orgPk };
                await payoutsClient.updatePayoutAccountInfo(data);
                getPayoutAccountInfo(orgPk);
            } catch (e) {
                let errorMessage = 'Could not update payout account info';
                if (HTTPError.isHTTPError(e) && (e.cause as { message: string }).message)
                    errorMessage = (e.cause as { message: string }).message;
                dispatch({
                    type: ADD_PAYOUT_ACCOUNT_INFO_FAILURE,
                    payload: { message: errorMessage },
                });
                return;
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<PayoutsContextType>(() => {
        return {
            ...state,
            getPayouts,
            getPayoutAccountInfo,
            addPayoutAccountInfo,
        };
    }, [state, getPayouts, getPayoutAccountInfo, addPayoutAccountInfo]);
    return <PayoutsContext.Provider value={value}>{children}</PayoutsContext.Provider>;
};

export const usePayouts = () => {
    const context = useContext(PayoutsContext);
    if (!context) {
        throw new Error('Error: usePayouts should be wrapped by PayoutsProvider.');
    }
    return context;
};
