export const customStyles = {
    placeholder: (provided: any, _state: any) => ({
        ...provided,
        marginLeft: 5,
        textAlign: 'left',
    }),
    input: (provided: any, _state: any) => ({
        ...provided,
        paddingLeft: -5,
        textAlign: 'left',
    }),
    menu: (provided: any, _state: any) => ({
        ...provided,
        textAlign: 'left',
    }),
    valueContainer: (provided: any, _state: any) => ({
        ...provided,
        textAlign: 'left',
        paddingRight: '0',
    }),
    container: (provided: any, _state: any) => ({
        ...provided,
        height: '100%',
    }),
    control: (provided: any, _state: any) => ({
        ...provided,
        height: '100%',
    }),
};

export const customTheme = (theme: any) => ({
    ...theme,
    textAlign: 'left',
    colors: {
        ...theme.colors,
        primary25: '#ffeecc',
        primary: 'hsl(0, 0%, 80%)',
        neutral0: '#F2F2F2',
        neutral20: '#F2F2F2',
    },
});
