import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import LoginView from './views/LoginView';
import AuthView from './views/AuthView';
import PrivateRoute from './components/Utilities/PrivateRoute';
import ValidationRequestsModuleContainer from './containers/ValidationRequestsModuleContainer';
import { history } from './store';
import { Divider } from 'semantic-ui-react';
import Footer from './components/Footer/Footer';
import OrganizationView from './views/OrganizationView';
import MainView from './views/MainView';
import DashboardContent from './components/Dashboard/DashboardContent/DashboardContent';
import CreateOrganizationView from './views/CreateOrganizationView';
import Sidebar from './containers/Sidebar';
import RefundsModuleContainer from './containers/RefundsModuleContainer';
import ValetDashboardView from 'views/ValetDashboard/ValetDashboard';

const ReactRouter = () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/login">
                    <LoginView />
                </Route>
                <Route path="/auth/:token">
                    <AuthView />
                </Route>
                <PrivateRoute path="/valet/:orgId">
                    <ValetDashboardView />
                </PrivateRoute>
                <PrivateRoute path="/refunds">
                    <Sidebar>
                        <DashboardContent title="Refunds">
                            <RefundsModuleContainer />
                        </DashboardContent>
                    </Sidebar>
                </PrivateRoute>
                <PrivateRoute path="/organization/new" exact strict>
                    <Sidebar>
                        <DashboardContent title={'Create New Organization'}>
                            <CreateOrganizationView />
                        </DashboardContent>
                    </Sidebar>
                </PrivateRoute>
                <PrivateRoute path={`/organization/:orgId/spots/:spotId/validation`}>
                    <ValidationRequestsModuleContainer />
                </PrivateRoute>
                <PrivateRoute path="/organization/:orgId">
                    <OrganizationView />
                    <Divider hidden />
                    <Footer />
                </PrivateRoute>
                <PrivateRoute path="/">
                    <MainView />
                </PrivateRoute>
            </Switch>
        </ConnectedRouter>
    );
};

export default ReactRouter;
