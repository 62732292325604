import {
    AggregatorReportInterface,
    BreakdownFrequencyReportInterface,
    DailyOccupancyReport,
    DriverFrequencyReportInterface,
    IncomeStatementReport,
    MonthlyOccupancyReport,
} from 'types';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import cache from './cache';

type ReportsClientServiceParams = HTTPClientParameters;

const tenMinutesInMilliseconds = 10 * 60000;
class ReportsClient extends HTTPClient {
    constructor(params: ReportsClientServiceParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    @cache(tenMinutesInMilliseconds)
    getReviewReport(spotUuid: string, days: number) {
        const reportRequest = `reports/reviews?spot=${spotUuid}&days=${days}`;
        return this.get(reportRequest);
    }

    @cache(tenMinutesInMilliseconds)
    getOverallFrequency(
        spotId: number,
        startDate?: string,
        endDate?: string
    ): Promise<{ data: DriverFrequencyReportInterface; last_updated_at: string }> {
        let overallFrequencyRequest = `reports/driver_insights/overall?spot_id=${spotId}`;
        if (startDate) overallFrequencyRequest += `&start_date_after=${startDate}`;
        if (endDate) overallFrequencyRequest += `&start_date_before=${endDate}`;
        return this.get(overallFrequencyRequest);
    }

    @cache(tenMinutesInMilliseconds)
    getBreakdownFrequency(
        spotId: number,
        startDate?: string,
        endDate?: string
    ): Promise<{ data: BreakdownFrequencyReportInterface; last_updated_at: string }> {
        let breakdownFrequencyRequest = `reports/driver_insights/day?spot_id=${spotId}`;
        if (startDate) breakdownFrequencyRequest += `&start_date_after=${startDate}`;
        if (endDate) breakdownFrequencyRequest += `&start_date_before=${endDate}`;
        return this.get(breakdownFrequencyRequest);
    }

    @cache(tenMinutesInMilliseconds)
    getMonthlyHeatmapReport(spotId: number): Promise<MonthlyOccupancyReport> {
        const request = `reports/drivers/insights/heatmap/monthly?spot_id=${spotId}`;
        return this.get(request);
    }

    @cache(tenMinutesInMilliseconds)
    getDailyHeatmapReport(spotId: number): Promise<DailyOccupancyReport> {
        const request = `reports/drivers/insights/heatmap/hourly?spot_id=${spotId}`;
        return this.get(request);
    }

    @cache(tenMinutesInMilliseconds)
    getIncomeStatementReport(orgUuid: string, startDate: string, endDate: string): Promise<IncomeStatementReport> {
        const request = `reports/income_statement?organization=${orgUuid}&start_date=${startDate}&end_date=${endDate}`;
        return this.get(request);
    }

    @cache(tenMinutesInMilliseconds)
    getAggregatorReports(spotPk: number, filters: { month: string; year: string }): Promise<AggregatorReportInterface> {
        return this.get(`reports/aggregators?spot=${spotPk}&month=${filters.month}&year=${filters.year}`);
    }
}

export default ReportsClient;
