import React from 'react';
import { Divider, Header, Icon, Image } from 'semantic-ui-react';
import { ProfileInterface, SpotInterface } from 'types';
import logo from 'assets/AirGarage.svg';

type ProfileBoxProps = {
    profile: ProfileInterface;
    selectedSpotInfo: SpotInterface | null;
};

const ProfileBox = (props: ProfileBoxProps) => {
    const { profile, selectedSpotInfo } = props;

    const { email, superuser, organization } = profile;
    return (
        <div style={{ margin: 20 }}>
            <Image src={logo} style={{ maxWidth: 150 }} />
            <Header as="h3">Welcome, {profile['first_name']}!</Header>
            <h5>
                <Icon name="mail outline"></Icon>
                {email}
            </h5>
            <p>
                <Icon name="building outline"></Icon>
                {organization}
            </p>
            <p>
                <Icon name="star outline"></Icon> {superuser ? 'Superuser' : profile['affiliation_role']}
            </p>
            <p>
                <Icon name="clock outline"></Icon>
                {selectedSpotInfo ? `${selectedSpotInfo.timezone} timezone` : 'Loading timezone'}
            </p>
            <Divider />
        </div>
    );
};

export default ProfileBox;
