import React from 'react';
import { connect } from 'react-redux';
import Payouts from 'components/Payouts/Payouts';
import { AppState } from 'reducers/reducers';
import { PayoutsProvider } from 'contexts/PayoutsContext';

type PayoutsModuleContainerProps = ReturnType<typeof mapStateToProps>;

function PayoutsModuleContainer({ organization }: PayoutsModuleContainerProps) {
    return (
        <PayoutsProvider>
            <Payouts organization={organization} />
        </PayoutsProvider>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
    };
}

export default connect(mapStateToProps)(PayoutsModuleContainer);
