import React, { useEffect, useMemo, useState } from 'react';
import { Organization } from 'types';
import { PAYOUT_PAGE_SIZE } from 'clients/PayoutsClient';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import Pagination from 'components/Pagination/Pagination';
import { usePayouts } from 'contexts/PayoutsContext';
import AGMessage from 'components/AGMessage/AGMessage';
import Loader from 'components/Loader/Loader';

interface PayoutTableProps {
    organization: Organization;
}

function PayoutsTable({ organization }: PayoutTableProps) {
    const [page, setPage] = useState(1);
    const { errorMessage, getPayouts, payouts, loading } = usePayouts();

    const totalPages = useMemo(() => {
        return payouts ? Math.ceil(payouts.count / PAYOUT_PAGE_SIZE) : 1;
    }, [payouts]);

    useEffect(() => {
        getPayouts(organization.pk, page);
    }, [getPayouts, organization, page]);

    return (
        <>
            {errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}

            {loading || !payouts ? (
                <Loader />
            ) : (
                <AGTAbleWrapper>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Statement Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payouts.results.map((payout, index) => {
                            return (
                                <tr key={index}>
                                    <td>{payout.local_date}</td>
                                    <td>{payout.formatted_amount}</td>
                                    <td>{payout.statement_descriptor}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    {totalPages > 1 && (
                        <tfoot>
                            <tr>
                                <td colSpan={3}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Pagination
                                            currentPage={page}
                                            onPageChange={(p) => setPage(Number(p))}
                                            totalPages={totalPages}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </AGTAbleWrapper>
            )}
        </>
    );
}

export default PayoutsTable;
