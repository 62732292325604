import { AggregatorReportInterface } from 'types';
import { AGGREGATOR_REPORTS_REQUESTED, AGGREGATOR_REPORTS_SUCCESS, AGGREGATOR_REPORTS_FAILED } from './constants';
import { Action } from '../useReducerWithMiddleware';

export type AggregatorReportState = {
    loading: boolean;
    errorMessage: string;
    reports: Record<string, AggregatorReportInterface>;
};

export const initialAggregatorReportState: AggregatorReportState = {
    loading: false,
    errorMessage: '',
    reports: {},
};

export default function aggregatorReportReducer(
    state: AggregatorReportState = initialAggregatorReportState,
    action: Action
): AggregatorReportState {
    switch (action.type) {
        case AGGREGATOR_REPORTS_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case AGGREGATOR_REPORTS_SUCCESS: {
            const { timeKey, report } = action.payload as {
                timeKey: string;
                report: AggregatorReportInterface;
            };
            return {
                ...state,
                loading: false,
                errorMessage: '',
                reports: {
                    ...state.reports,
                    [timeKey]: report,
                },
            };
        }
        case AGGREGATOR_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialAggregatorReportState;
    }
}
