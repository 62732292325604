import APIClient from './APIClient';
import Cookies from 'js-cookie';
import env from 'env';
import moment from 'moment';
import { DailyReportInterface, PromoCodeReportInterface, ReportInterface } from 'types';
import { rentalTypes } from 'utils/constants';
import { dateToUnix, downloadFile } from 'utils/helpers';

type CommonFilter = {
    rentalType?: (typeof rentalTypes)[0]['value'];
};
export type DateFilter = {
    month: string;
    year: string;
};
export type FiltersWithDate = CommonFilter & DateFilter;
type FiltersWithPayout = CommonFilter & {
    payout: string;
};
export type DailyReportFilters = FiltersWithDate | FiltersWithPayout;

class ReportsService {
    getReports(
        spotPk: number,
        filters: { date: { month: number; year: number } } | undefined,
        token: string
    ): Promise<ReportInterface> {
        let reportRequest = `reports/reports?spot=${spotPk}`;
        if (filters) {
            reportRequest += `&month=${filters.date.month}&year=${filters.date.year}`;
        }
        return APIClient.get(reportRequest, token);
    }

    getPromoCodeReports(
        spotPk: number,
        filters: { date: DateFilter },
        token: string
    ): Promise<PromoCodeReportInterface> {
        let reportRequest = `reports/promocodes?spot=${spotPk}`;
        if (filters) {
            reportRequest += `&month=${filters.date.month}&year=${filters.date.year}`;
        }
        return APIClient.get(reportRequest, token);
    }

    getDailyReports(spotPk: number, filters: DailyReportFilters, token: string): Promise<DailyReportInterface> {
        let reportRequest = `reports/daily_reports?spot=${spotPk}`;
        if (filters.rentalType) {
            reportRequest += `&rental_type=${filters.rentalType}`;
        }
        if ('payout' in filters && filters.payout) {
            reportRequest += `&payout_transaction=${filters.payout}`;
        } else {
            const { month, year } = filters as FiltersWithDate;
            reportRequest += `&month=${month}&year=${year}`;
        }
        return APIClient.get(reportRequest, token);
    }

    downloadDailyReports(
        spotUuid: string,
        filters: {
            rentalType?: string;
            startDate: Date;
            endDate: Date;
        }
    ): Promise<void> {
        const startDateFormatted = moment.utc(filters.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment.utc(filters.endDate).format('MM-DD-YYYY');
        const rentalTypeParam = filters.rentalType ? `&rental_type=${filters.rentalType}` : '';
        const link = `${env.DOWNLOAD_DOMAIN}dailyrevenue/${spotUuid}?start_date=${dateToUnix(
            filters.startDate
        )}&end_date=${dateToUnix(filters.endDate)}${rentalTypeParam}`;
        const headers = new Headers();
        const token = Cookies.get('airgarage_auth');
        if (token) {
            headers.append('Authorization', `Token ${token}`);
        }
        fetch(link, {
            headers,
        })
            .then((response) => response.blob())
            .then((blob) => {
                const rentalTypeName = filters.rentalType ? filters.rentalType : 'all';
                const fileName = `revenue_reports_${rentalTypeName}_${startDateFormatted}_${endDateFormatted}.csv`;
                downloadFile({
                    data: blob,
                    fileName,
                    fileType: 'csv',
                });
            });
        return Promise.resolve();
    }
}

export default new ReportsService();
