import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import { replace, push } from 'connected-react-router';

import { getSpotsAction } from 'actions/actions';
import AddSpot from 'components/Dashboard/AddSpot/AddSpot';
import DashboardContent from 'components/Dashboard/DashboardContent/DashboardContent';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import CalendarModuleContainer from './CalendarModuleContainer';
import RatesCalendarModuleContainer from './RatesCalendarModuleContainer';
import EnforcementsModuleContainer from './EnforcementsModuleContainer';
import CurrentlyParkedModuleContainer from './CurrentlyParkedModuleContainer';
import SlotsModuleContainer from './SlotsModuleContainer';
import AggregatorRentalsContainer from './AggregatorRentalsContainer';
import CheckPlateModuleContainer from './CheckPlateModuleContainer';
import VisitorsModuleContainer from './VisitorsModuleContainer';
import VisitorCodesModuleContainer from './VisitorCodesModuleContainer';
import PromoCodesModuleContainer from './PromoCodesModuleContainer';
import GraphsModuleContainer from './GraphsModuleContainer';
import CustomReportsModuleContainer from './CustomReportsModuleContainer';
import ReportsModuleContainer from './ReportsModuleContainer';
import UploadVisitorsCSVModuleContainer from './UploadVisitorsCSVModuleContainer';
import ValidationGeneratorModuleContainer from './ValidationGeneratorModuleContainer';
import SpotDetailContainer from './SpotDetailContainer';
import DriverInsightsContainer from './DriverInsightsContainer';
import SpotTimelineContainer from './LotTimelineContainer';
import TicketModuleContainer from './TicketModuleContainer';
import DriversModuleContainer from './DriversModuleContainer';
import VisibilityPageContainer from './VisibilityPageContainer';

type SpotsContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const SpotsContainer = ({
    orgSpots,
    replace: connectedReplace,
    push: connectedPush,
    organization,
    orgSpotsLoading,
    getSpots,
    spotIdInUrl,
    orgIdInUrl,
}: SpotsContainerProps): JSX.Element => {
    useEffect(() => {
        getSpots();
    }, [getSpots]);

    const { isExact: isSpotsIndexRoute } = useRouteMatch('/organization/:orgId/spots') || { isExact: false };
    const { isExact: isSpotPageWithoutPath } = useRouteMatch<{ orgId: string; spotId: string }>(
        '/organization/:orgId/spots/:spotId'
    ) || { isExact: false };

    const spotArr = useMemo(() => orgSpots && Object.values(orgSpots), [orgSpots]);
    const hasAtLeastOneSpot = spotArr ? spotArr.length > 0 : null;

    let spotIdInUrlIsInThisOrg = true;
    if (orgSpots && spotIdInUrl && !(spotIdInUrl in orgSpots)) {
        spotIdInUrlIsInThisOrg = false;
    }

    useEffect(() => {
        // New organization will load soon, don't do anything
        if (organization.pk !== orgIdInUrl) return;
        if (isSpotsIndexRoute && orgSpots && hasAtLeastOneSpot) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            connectedReplace(`/organization/${organization.pk}/spots/${spotArr![0].pk}/detail`);
        } else {
            // We are in some sort of spot-specific page (with or without a path after it)
            // so spotIdInUrl should be a valid number
            if (!spotIdInUrlIsInThisOrg) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                connectedReplace(`/organization/${organization.pk}/spots/${spotArr![0].pk}/detail`);
            } else if (isSpotPageWithoutPath) {
                connectedReplace(`/organization/${organization.pk}/spots/${spotIdInUrl}/detail`);
            }
        }
    }, [
        organization.pk,
        orgIdInUrl,
        isSpotsIndexRoute,
        spotArr,
        hasAtLeastOneSpot,
        spotIdInUrlIsInThisOrg,
        isSpotPageWithoutPath,
        spotIdInUrl,
        orgSpots,
        connectedReplace,
    ]);

    if (hasAtLeastOneSpot) {
        return (
            <Switch>
                <Route path="/organization/:organizationId/spots/:spotId/detail">
                    <SpotDetailContainer />
                    <AddSpot urlPush={connectedPush} organizationId={organization.pk} />
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/events">
                    <DashboardContent title="Events">
                        <CalendarModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/rates">
                    <AuthorizedView allowed={Roles.Superuser}>
                        <DashboardContent title="Rates">
                            <RatesCalendarModuleContainer />
                        </DashboardContent>
                    </AuthorizedView>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/enforcement">
                    <DashboardContent title="Enforcement">
                        <EnforcementsModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/parked">
                    <DashboardContent title="Currently parked">
                        <CurrentlyParkedModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/invoice">
                    <AuthorizedView allowed={Roles.Superuser}>
                        <DashboardContent title="Create an Invoice">
                            <SlotsModuleContainer />
                        </DashboardContent>
                    </AuthorizedView>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/aggregator-rentals">
                    <DashboardContent title="Aggregator Rentals">
                        <AggregatorRentalsContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/check-plate">
                    <DashboardContent title="Plate Lookup">
                        <CheckPlateModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/visitors">
                    <DashboardContent title="Visitors">
                        <VisitorsModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/codes">
                    <DashboardContent title="Validation Codes">
                        <VisitorCodesModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/promos">
                    <DashboardContent title="Discount Codes">
                        <PromoCodesModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/drivers">
                    <DashboardContent title="Drivers">
                        <DriversModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/graphs">
                    <DashboardContent title="Graphs">
                        <GraphsModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/custom-reports">
                    <DashboardContent title="Custom Reports">
                        <CustomReportsModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/reports">
                    <DashboardContent title="Reports">
                        <ReportsModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/upload-visitors-csv">
                    <DashboardContent title="Upload Visitors CSV">
                        <UploadVisitorsCSVModuleContainer />
                    </DashboardContent>
                </Route>

                <Route path="/organization/:organizationId/spots/:spotId/kiosk-generator">
                    <DashboardContent title="Validation Kiosk Setup">
                        <ValidationGeneratorModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/driver-insights">
                    <DriverInsightsContainer />
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/timeline">
                    <SpotTimelineContainer />
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/violations">
                    <DashboardContent title="Waive a Violation">
                        <TicketModuleContainer />
                    </DashboardContent>
                </Route>
                <Route path="/organization/:organizationId/spots/:spotId/visibility">
                    <DashboardContent title="Visibility">
                        <VisibilityPageContainer />
                    </DashboardContent>
                </Route>
            </Switch>
        );
    }

    return (
        <Grid>
            {orgSpotsLoading ? (
                <Loader inline active />
            ) : (
                // There are no spots for this organization yet.
                <>
                    <Grid.Row>
                        <h3>No parking lots here yet...</h3>
                    </Grid.Row>
                    <AddSpot urlPush={connectedPush} organizationId={organization.pk} />
                </>
            )}
        </Grid>
    );
};

function mapStateToProps(state: AppState) {
    return {
        orgSpots: state.spots.orgSpots,
        orgSpotsLoading: state.spots.orgSpotsLoading,
        spotIdInUrl: selectedSpotId(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        orgIdInUrl: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getSpots: getSpotsAction,
    replace,
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotsContainer);
