import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logo from 'assets/AirGarage.svg';
import parkingLot from 'assets/parkingLot.webp';
import LoginForm from 'components/LoginForm/LoginForm';
import { parseQueryParams } from 'utils/helpers';
import styles from './LoginView.module.css';

function LoginView(props: RouteComponentProps) {
    const urlParams = parseQueryParams(props.location.search);

    return (
        <div className={styles.container}>
            <div className={styles.loginForm}>
                <img src={logo} alt="AirGarage" height={32} />
                <LoginForm urlParams={urlParams} />
            </div>
            <div>
                <img src={parkingLot} alt="" width="100%" height="100%" className={styles.backgroundImage} />
            </div>
        </div>
    );
}

export default withRouter(LoginView);
