import APIClient from './APIClient';
import Cookies from 'js-cookie';
import { ProfileInterface } from 'types';

class ProfileService {
    async getToken(email: string, password: string) {
        const result = await APIClient.post('auth/', { username: email, password: password });
        const token = result['token'];
        if (process.env.NODE_ENV === 'development') {
            Cookies.set('airgarage_auth', token, { path: '/', expires: 2000 });
        } else {
            Cookies.set('airgarage_auth', token, { domain: 'airgara.ge', path: '/', expires: 2000 });
            Cookies.set('airgarage_auth', token, { domain: 'airgarage.com', path: '/', expires: 2000 });
        }
        return token;
    }
    sendVerificationCode(username: string, phoneCode: string) {
        return APIClient.post('otp/', { username, phone_country_code: phoneCode });
    }
    getProfileInfo(token: string): Promise<ProfileInterface> {
        return APIClient.get('v2/profiles/me', token);
    }
    async getAirGarageProfiles(token: string) {
        return APIClient.get(`profiles/?user__is_superuser=true`, token);
    }
}

export default new ProfileService();
