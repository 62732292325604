export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SEND_VERIFICATION_CODE_REQUESTED = 'SEND_VERIFICATION_CODE_REQUESTED';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILED = 'SEND_VERIFICATION_CODE_FAILED';

export const LOGOUT = 'LOGOUT';

export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILED = 'PROFILE_FAILED';

export const SALES_REPS_REQUESTED = 'SALES_REPS_REQUESTED';
export const SALES_REPS_SUCCESS = 'SALES_REPS_SUCCESS';
export const SALES_REPS_FAILED = 'SALES_REPS_FAILED';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const ORGANIZATION_REQUESTED = 'ORGANIZATION_REQUESTED';
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';
export const ORGANIZATION_FAILED = 'ORGANIZATION_FAILED';

export const ORGANIZATION_LIST_REQUESTED = 'ORGANIZATION_LIST_REQUESTED';
export const ORGANIZATION_LIST_SUCCESS = 'ORGANIZATION_LIST_SUCCESS';
export const ORGANIZATION_LIST_FAILED = 'ORGANIZATION_LIST_FAILED';

export const SET_ORGANIZATION = 'SET_ORGANIZATION';

export const CLEAR_DATA = 'CLEAR_DATA';

export const SPOT_LIST_REQUESTED = 'SPOT_LIST_REQUESTED';
export const SPOT_LIST_SUCCESS = 'SPOT_LIST_SUCCESS';
export const SPOT_LIST_FAILED = 'SPOT_LIST_FAILED';

export const ORGANIZATION_SPOTS_REQUESTED = 'ORGANIZATION_SPOTS_REQUESTED';
export const ORGANIZATION_SPOTS_SUCCESS = 'ORGANIZATION_SPOTS_SUCCESS';
export const ORGANIZATION_SPOTS_FAILED = 'ORGANIZATION_SPOTS_FAILED';

export const ADD_TEAM_REQUESTED = 'ADD_TEAM_REQUESTED';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILED = 'ADD_TEAM_FAILED';

export const DELETE_TEAM_REQUESTED = 'DELETE_TEAM_REQUESTED';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED';

export const TEAM_REQUESTED = 'TEAM_REQUESTED';
export const TEAM_SUCCESS = 'TEAM_SUCCESS';
export const TEAM_FAILED = 'TEAM_FAILED';

export const REPORTS_REQUESTED = 'REPORTS_REQUESTED';
export const REPORTS_SUCCESS = 'REPORTS_SUCCESS';
export const REPORTS_FAILED = 'REPORTS_FAILED';

export const UPDATE_REPORTS_REQUESTED = 'UPDATE_REPORTS_REQUESTED';
export const UPDATE_REPORTS_SUCCESS = 'UPDATE_REPORTS_SUCCESS';
export const UPDATE_REPORTS_FAILED = 'UPDATE_REPORTS_FAILED';

export const PROMO_CODE_REPORTS_REQUESTED = 'PROMO_CODE_REPORTS_REQUESTED';
export const PROMO_CODE_REPORTS_SUCCESS = 'PROMO_CODE_REPORTS_SUCCESS';
export const PROMO_CODE_REPORTS_FAILED = 'PROMO_CODE_REPORTS_FAILED';

export const CURRENTLY_PARKED_REQUESTED = 'CURRENTLY_PARKED_REQUESTED';
export const CURRENTLY_PARKED_SUCCESS = 'CURRENTLY_PARKED_SUCCESS';
export const CURRENTLY_PARKED_FAILED = 'CURRENTLY_PARKED_FAILED';

export const CHECK_PLATE_REQUESTED = 'CHECK_PLATE_REQUESTED';
export const CHECK_PLATE_SUCCESS = 'CHECK_PLATE_SUCCESS';
export const CHECK_PLATE_FAILED = 'CHECK_PLATE_FAILED';

export const ENFORCEMENTS_REQUESTED = 'ENFORCEMENTS_REQUESTED';
export const ENFORCEMENTS_SUCCESS = 'ENFORCEMENTS_SUCCESS';
export const ENFORCEMENTS_FAILED = 'ENFORCEMENTS_FAILED';

export const UPDATE_ENFORCEMENT_REQUESTED = 'UPDATE_ENFORCEMENT_REQUESTED';
export const UPDATE_ENFORCEMENT_SUCCESS = 'UPDATE_ENFORCEMENT_SUCCESS';
export const UPDATE_ENFORCEMENT_FAILED = 'UPDATE_ENFORCEMENT_FAILED';

export const ADD_ENFORCEMENT_PENALTY_REQUESTED = 'ADD_ENFORCEMENT_PENALTY_REQUESTED';
export const ADD_ENFORCEMENT_PENALTY_SUCCESS = 'ADD_ENFORCEMENT_PENALTY_SUCCESS';
export const ADD_ENFORCEMENT_PENALTY_FAILED = 'ADD_ENFORCEMENT_PENALTY_FAILED';

export const DAILY_REPORTS_REQUESTED = 'DAILY_REPORTS_REQUESTED';
export const DAILY_REPORTS_SUCCESS = 'DAILY_REPORTS_SUCCESS';
export const DAILY_REPORTS_FAILED = 'DAILY_REPORTS_FAILED';

export const ADD_ORGANIZATION_REQUESTED = 'ADD_ORGANIZATION_REQUESTED';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAILED = 'ADD_ORGANIZATION_FAILED';

export const UPLOAD_SPOT_PHOTO_REQUESTED = 'UPLOAD_SPOT_PHOTO_REQUESTED';
export const UPLOAD_SPOT_PHOTO_SUCCESS = 'UPLOAD_SPOT_PHOTO_SUCCESS';
export const UPLOAD_SPOT_PHOTO_FAILED = 'UPLOAD_SPOT_PHOTO_FAILED';

export const UPLOAD_LOGO_REQUESTED = 'UPLOAD_LOGO_REQUESTED';
export const UPLOAD_LOGO_SUCCESS = 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_FAILED = 'UPLOAD_LOGO_FAILED';

export const ADD_SPOT_REQUESTED = 'ADD_SPOT_REQUESTED';
export const ADD_SPOT_SUCCESS = 'ADD_SPOT_SUCCESS';
export const ADD_SPOT_FAILED = 'ADD_SPOT_FAILED';

export const REFUNDS_REQUESTED = 'REFUNDS_REQUESTED';
export const REFUNDS_SUCCESS = 'REFUNDS_SUCCESS';
export const REFUNDS_FAILED = 'REFUNDS_FAILED';

export const ISSUE_REFUND_REQUESTED = 'ISSUE_REFUND_REQUESTED';
export const ISSUE_REFUND_SUCCESS = 'ISSUE_REFUND_SUCCESS';
export const ISSUE_REFUND_FAILED = 'ISSUE_REFUND_FAILED';

export const IGNORE_REFUND_REQUESTED = 'IGNORE_REFUND_REQUESTED';
export const IGNORE_REFUND_SUCCESS = 'IGNORE_REFUND_SUCCESS';
export const IGNORE_REFUND_FAILED = 'IGNORE_REFUND_FAILED';

export const ADD_PROMO_CODE_REQUESTED = 'ADD_PROMO_CODE_REQUESTED';
export const ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS';
export const ADD_PROMO_CODE_FAILED = 'ADD_PROMO_CODE_FAILED';

export const PROMO_CODES_REQUESTED = 'PROMO_CODES_REQUESTED';
export const PROMO_CODES_SUCCESS = 'PROMO_CODES_SUCCESS';
export const PROMO_CODES_FAILED = 'PROMO_CODES_FAILED';

export const DELETE_PROMO_CODE_REQUESTED = 'DELETE_PROMO_CODE_REQUESTED';
export const DELETE_PROMO_CODE_SUCCESS = 'DELETE_PROMO_CODE_SUCCESS';
export const DELETE_PROMO_CODE_FAILED = 'DELTE_PROMO_CODE_FAILED';

export const VISITORS_TO_VALIDATE_REQUESTED = 'VISITORS_TO_VALIDATE_REQUESTED';
export const VISITORS_TO_VALIDATE_SUCCESS = 'VISITORS_TO_VALIDATE_SUCCESS';
export const VISITORS_TO_VALIDATE_FAILED = 'VISITORS_TO_VALIDATE_FAILED';

export const ADD_VISITOR_TO_VALIDATE_REQUESTED = 'ADD_VISITOR_TO_VALIDATE_REQUESTED';
export const ADD_VISITOR_TO_VALIDATE_SUCCESS = 'ADD_VISITOR_TO_VALIDATE_SUCCESS';
export const ADD_VISITOR_TO_VALIDATE_FAILED = 'ADD_VISITOR_TO_VALIDATE_FAILED';

export const VALIDATE_VISITOR_REQUESTED = 'VALIDATE_VISITOR_REQUESTED';
export const VALIDATE_VISITOR_SUCCESS = 'VALIDATE_VISITOR_SUCCESS';
export const VALIDATE_VISITOR_FAILED = 'VALIDATE_VISITOR_FAILED';

export const UPLOAD_VISITOR_CSV_REQUESTED = 'UPLOAD_VISITOR_CSV_REQUESTED';
export const UPLOAD_VISITOR_CSV_SUCCESS = 'UPLOAD_VISITOR_CSV_SUCCESS';
export const UPLOAD_VISITOR_CSV_FAILED = 'UPLOAD_VISITOR_CSV_FAILED';

export const PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED = 'PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED';
export const PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS = 'PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS';
export const PROGRESS_UPLOAD_VISITOR_CSV_FAILED = 'PROGRESS_UPLOAD_VISITOR_CSV_FAILED';

export const SPOT_PRICING_REQUESTED = 'SPOT_PRICING_REQUESTED';
export const SPOT_PRICING_SUCCESS = 'SPOT_PRICING_SUCCESS';
export const SPOT_PRICING_FAILED = 'SPOT_PRICING_FAILED';

export const SPOT_MULTIDAY_PRICING_REQUESTED = 'SPOT_MULTIDAY_PRICING_REQUESTED';
export const SPOT_MULTIDAY_PRICING_SUCCESS = 'SPOT_MULTIDAY_PRICING_SUCCESS';
export const SPOT_MULTIDAY_PRICING_FAILED = 'SPOT_MULTIDAY_PRICING_FAILED';

export const GET_TICKET_REQUESTED = 'GET_TICKET_REQUESTED';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_FAILED = 'GET_TICKET_FAILED';

export const WAIVE_TICKET_REQUESTED = 'WAIVE_TICKET_REQUESTED';
export const WAIVE_TICKET_SUCCESS = 'WAIVE_TICKET_SUCCESS';
export const WAIVE_TICKET_FAILED = 'WAIVE_TICKET_FAILED';
