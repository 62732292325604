import React from 'react';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { Errors, getErrorMessage } from 'components/ValetDashboard/utils/errors';
import Label from '../Label/Label';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errors: Errors;
    icon?: React.ReactNode;
    isUppercase?: boolean;
    required?: boolean;
}

function TextInput({
    label,
    errors,
    isUppercase = false,
    required = false,
    onChange = () => {},
    ...props
}: TextInputProps): JSX.Element {
    const errorMessage = getErrorMessage(errors);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (isUppercase) {
            event.target.value = event.target.value.toUpperCase();
        }

        onChange(event);
    }

    return (
        <div
            className={`input-wrapper ${errorMessage && 'error'} ${props.disabled && 'disabled'} ${props.className}`}
            style={props.width ? { width: props.width } : {}}
        >
            {label && <Label icon={props.icon} label={label} required={required} />}
            <input type="text" {...props} onChange={handleChange} />
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
}

export default TextInput;
