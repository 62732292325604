import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HeroStats } from 'types/CarTracking';
import styles from './HeroStatsVisibility.module.css';

type OccupancyBarsProps = {
    heroStats: HeroStats;
};

type BarsData = {
    barsArray: null[];
} & Record<'occupancy' | 'peak', { index: number; value: number }>;

const BAR_WIDTH = 20;
const BARS_GAP = 8;

function calculateBarsData(heroStats: HeroStats, totalBars: number): BarsData {
    const barsArray = new Array(totalBars).fill(null);
    const { max_capacity: maxCapacity } = heroStats;

    const occupancy = heroStats.occupancy <= maxCapacity ? heroStats.occupancy : maxCapacity;
    const peak = heroStats.peak <= maxCapacity ? heroStats.peak : maxCapacity;

    const relativeOccupancy = occupancy / maxCapacity;
    const relativePeak = peak / maxCapacity;

    const occupancyBarLimit = Math.max(Math.round(relativeOccupancy * totalBars) - 1, 0);
    let peakBar = Math.max(Math.round(relativePeak * totalBars) - 1, 0);

    if (occupancyBarLimit === peakBar && occupancy !== peak) {
        peakBar = occupancy > peak ? peakBar - 1 : peakBar + 1;
    }

    return {
        barsArray,
        occupancy: { index: occupancyBarLimit, value: occupancy },
        peak: { index: peakBar, value: peak },
    };
}

function OccupancyBars({ heroStats }: OccupancyBarsProps): JSX.Element {
    const [barsData, setBarsData] = useState<BarsData>();
    const barsContainerRef = useRef<HTMLDivElement>(null);

    const onResize = useCallback(() => {
        if (!barsContainerRef.current) return;
        const containerWidth = barsContainerRef.current.offsetWidth;

        setBarsData(calculateBarsData(heroStats, Math.floor(containerWidth / (BAR_WIDTH + BARS_GAP))));
    }, [heroStats]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    return (
        <div className={styles.barsAndMaxContainer}>
            <div ref={barsContainerRef} className={styles.barsContainer}>
                {barsData &&
                    (function () {
                        const { occupancy, peak, barsArray } = barsData;
                        return (
                            <>
                                <div
                                    style={{ left: (BAR_WIDTH + BARS_GAP) * occupancy.index + BAR_WIDTH / 2 }}
                                    className={styles.barLabel}
                                >
                                    <span>{occupancy.value}</span>
                                    <br />
                                    <span>Currently Parked</span>
                                    <div className={styles.line} />
                                </div>

                                <div
                                    style={{
                                        bottom: 0,
                                        left: (BAR_WIDTH + BARS_GAP) * peak.index + BAR_WIDTH / 2,
                                    }}
                                    className={styles.barLabel}
                                >
                                    <div className={styles.line} />
                                    <span>{peak.value}</span>
                                    <br />
                                    <span>Today's Peak</span>
                                </div>
                                {barsArray.map((_, i) => {
                                    return (
                                        <div
                                            style={{
                                                width: BAR_WIDTH + 'px',
                                                marginRight: BARS_GAP + 'px',
                                            }}
                                            className={`${styles.bar} 
                                    ${i <= occupancy.index ? styles.filled : ''} 
                                    ${i === peak.index ? styles.peak : ''}`}
                                            key={i}
                                        ></div>
                                    );
                                })}
                            </>
                        );
                    })()}
            </div>
            <div className={styles.maxCapacity}>
                <span>{heroStats.max_capacity}</span>
                <span>Max capacity</span>
            </div>
        </div>
    );
}

export default OccupancyBars;
