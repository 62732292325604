export const PAYOUTS_REQUESTED = 'PAYOUTS_REQUESTED';
export const PAYOUTS_SUCCESS = 'PAYOUTS_SUCCESS';
export const PAYOUTS_FAILED = 'PAYOUTS_FAILED';

export const PAYOUT_ACCOUNT_INFO_REQUESTED = 'PAYOUT_ACCOUNT_INFO_REQUESTED';
export const PAYOUT_ACCOUNT_INFO_SUCCESS = 'PAYOUT_ACCOUNT_INFO_SUCCESS';
export const PAYOUT_ACCOUNT_INFO_FAILED = 'PAYOUT_ACCOUNT_INFO_FAILED';

export const ADD_PAYOUT_ACCOUNT_INFO_REQUESTED = 'ADD_PAYOUT_ACCOUNT_INFO_REQUESTED';
export const ADD_PAYOUT_ACCOUNT_INFO_FAILURE = 'ADD_PAYOUT_ACCOUNT_INFO_FAILURE';
