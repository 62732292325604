import React from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import { PaginatedPromoCodes, ValidatedDiscountCode } from 'types';
import Pagination from 'components/Pagination/Pagination';

type PromoCodeTableProps = {
    promoCodes: PaginatedPromoCodes;
    deletePromoCode: (dc: ValidatedDiscountCode) => void;
    openQrCodeModal: (dc: ValidatedDiscountCode) => void;
    spotTimezone: string;
    handlePaginationChange: (page: string) => void;
};

function PromoCodeTable({
    promoCodes,
    deletePromoCode,
    openQrCodeModal,
    spotTimezone,
    handlePaginationChange,
}: PromoCodeTableProps) {
    if (!promoCodes || promoCodes.results.length === 0) return <p>No discount codes</p>;

    return (
        <AGTAbleWrapper>
            <thead>
                <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Valid For</th>
                    <th>Amount</th>
                    <th>Expiration</th>
                    <th>Utilization</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {promoCodes.results.map((promoCode, index: number) => {
                    // Converts UTC datetime from promocode to the timezone of the Spot

                    const hasExpDateAndTZ = promoCode?.expiration && !!spotTimezone;
                    const expiration_date = hasExpDateAndTZ
                        ? moment.utc(promoCode.expiration).tz(spotTimezone).format('L LT')
                        : 'Never';

                    const usesLeft = promoCode.number_of_uses
                        ? promoCode.number_of_uses - promoCode.total_uses
                        : promoCode.total_uses;
                    const usage = `${usesLeft} ${usesLeft === 1 ? 'use' : 'uses'}`;
                    const formattedValueSuffix = `${promoCode.minutes_to_discount ? 'free' : 'off'}`;
                    const formattedApplicableRentalTypes = promoCode.applicable_rental_types
                        ? promoCode.applicable_rental_types
                              // Capitalize first letter of each word
                              .map((type) => type.replace(/\b\w/g, (l) => l.toUpperCase()))
                              .join(', ')
                        : 'All Rental Types';
                    return (
                        <tr key={index}>
                            <td>{promoCode.code.toUpperCase()}</td>
                            <td>{promoCode.notes}</td>
                            <td>{formattedApplicableRentalTypes}</td>
                            <td>{`${promoCode.formatted_value} ${formattedValueSuffix}`}</td>
                            <td>{expiration_date}</td>
                            <td>
                                {promoCode.number_of_uses
                                    ? `${usage} left out of ${promoCode.number_of_uses}`
                                    : `${usage}, no limit`}
                            </td>
                            <td>
                                <Button
                                    size="mini"
                                    onClick={() => openQrCodeModal(promoCode)}
                                    icon="download"
                                    float="right"
                                    style={{ color: '#7A7A7A', backgroundColor: 'white' }}
                                />
                                <Button
                                    size="mini"
                                    onClick={() => deletePromoCode(promoCode)}
                                    icon="trash"
                                    float="right"
                                    style={{ color: '#7A7A7A', backgroundColor: 'white' }}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
            {promoCodes.total_pages > 1 && (
                <tfoot>
                    <tr>
                        <td colSpan={7}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination
                                    currentPage={promoCodes.current_page}
                                    onPageChange={handlePaginationChange}
                                    totalPages={promoCodes.total_pages}
                                />
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </AGTAbleWrapper>
    );
}

export default PromoCodeTable;
