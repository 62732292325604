import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { Visitor } from 'types/Visitor';
import VisitorCardAndForm from '../VisitorCardAndForm';
import VisitorForm, { VisitorFormState } from '../VisitorForm/VisitorForm';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import AGButton from 'components/Button/Button';
import SearchInput from 'components/AGForm/Inputs/SearchInput/SearchInput';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import styles from './Visitors.module.css';
import AGSimpleSelect, { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import AGMessage from 'components/AGMessage/AGMessage';
import Pagination from 'components/Pagination/Pagination';
import Modal, { ModalRef } from 'components/Modal/Modal';
import { VISITOR_PAGE_SIZE } from 'services/VisitorService';
import { useVisitors } from 'contexts/VisitorsContext';
import { VisitorValidationSource } from 'utils/constants';
import { useVisitorsCode } from 'contexts/VisitorCodesContext';

type VisitorsModuleProps = {
    setSearchTerm: (st: string) => void;
    navigateTo: (path: string) => void;
    setShowExpiringVisitors: (b: boolean) => void;
    setPage: (page: string) => void;
    filters: {
        expiring: 'true' | 'false';
        searchTerm: string;
        currentSearchTerm: string;
        page: string;
    };
    selectedSpotPk: number;
};

const FILTER_OPTIONS: SelectOption<{ urlValue: boolean }>[] = [
    {
        label: 'All Valid Visitors',
        value: 'false',
        urlValue: false,
    },
    {
        label: 'Expiring Soon Visitors',
        value: 'true',
        urlValue: true,
    },
];

function VisitorsModule({
    filters,
    setPage,
    setSearchTerm,
    setShowExpiringVisitors,
    navigateTo,
    selectedSpotPk,
}: VisitorsModuleProps) {
    const [currentVisitor, setCurrentVisitor] = useState<Visitor | null>(null);
    const visitorFormModalRef = useRef<ModalRef>();

    const {
        visitors: paginatedVisitors,
        loading,
        errorMessage,
        successMessage,
        getVisitors,
        addVisitor,
        editVisitor,
        deleteVisitor,
    } = useVisitors();

    const { formattedVisitorCodes, getVisitorCodes } = useVisitorsCode();

    const visitors = paginatedVisitors?.results;
    const totalPages = paginatedVisitors ? Math.ceil(paginatedVisitors.count / VISITOR_PAGE_SIZE) : 1;
    const expiring = filters.expiring === 'true' ? true : false;

    function searchVisitors(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchTerm(event.target.value);
    }

    function getOptionFromValue(value: string) {
        return FILTER_OPTIONS.find((o) => o.value === value) || FILTER_OPTIONS[0];
    }

    function onAddVisitor(v: VisitorFormState) {
        addVisitor(
            {
                ...v,
                codes: v.code ? [v.code] : [],
                spot: selectedSpotPk,
                validation_source: VisitorValidationSource.DASHBOARD,
            },
            selectedSpotPk,
            { page: Number(filters.page), expiring, search: filters.searchTerm }
        );
        closeModal();
    }

    function onEditVisitor(v: VisitorFormState, visitorPk: number) {
        editVisitor(
            {
                ...v,
                codes: v.code ? [v.code] : [],
                spot: selectedSpotPk,
                validation_source: VisitorValidationSource.DASHBOARD,
            },
            visitorPk,
            selectedSpotPk,
            { page: Number(filters.page), expiring, search: filters.searchTerm }
        );
        closeModal();
    }

    function onDeleteVisitor(visitorPk: number) {
        deleteVisitor(visitorPk, selectedSpotPk, { page: Number(filters.page), expiring, search: filters.searchTerm });
        closeModal();
    }

    function openModal(visitor?: Visitor) {
        if (visitor) setCurrentVisitor(visitor);
        visitorFormModalRef.current?.openModal();
    }

    function closeModal() {
        visitorFormModalRef.current?.closeModal();
        if (currentVisitor) setCurrentVisitor(null);
    }

    useEffect(() => {
        getVisitors(selectedSpotPk, { page: Number(filters.page), expiring, search: filters.searchTerm });
    }, [expiring, filters.page, filters.searchTerm, getVisitors, selectedSpotPk]);

    useEffect(() => {
        getVisitorCodes(selectedSpotPk);
    }, [selectedSpotPk, getVisitorCodes]);

    return (
        <div className={styles.moduleContainer}>
            {errorMessage && (
                <AGMessage color="error" title="Error">
                    {errorMessage}
                </AGMessage>
            )}
            {successMessage && <AGMessage color="success">{successMessage}</AGMessage>}
            <div className={styles.addButtonContainer}>
                <AGButton size="sm" onClick={() => openModal()} className={styles.primaryButton}>
                    + Add a Visitor
                </AGButton>
            </div>
            <div className={styles.filters}>
                <SearchInput
                    onChange={searchVisitors}
                    value={filters.currentSearchTerm}
                    placeholder="Search by plate, name, or phone"
                    autoFocus
                    className={styles.searchInput}
                    type="text"
                />
                <AGSimpleSelect
                    selected={getOptionFromValue(filters.expiring).value}
                    options={FILTER_OPTIONS}
                    onSelect={(option) => {
                        setShowExpiringVisitors(getOptionFromValue(option).urlValue);
                    }}
                    width="200px"
                />
                <AGButton
                    size="sm"
                    color="info"
                    onClick={() => {
                        navigateTo('upload-visitors-csv');
                    }}
                    className={styles.secondaryButton}
                >
                    <UploadIcon />
                    Bulk Visitor Upload
                </AGButton>
                <AGButton
                    size="sm"
                    color="info"
                    onClick={() => {
                        navigateTo('kiosk-generator');
                    }}
                    className={styles.secondaryButton}
                >
                    <CheckIcon height={18} />
                    Validation Requests
                </AGButton>
            </div>

            {loading ? (
                <Loader active inline />
            ) : visitors && visitors.length > 0 ? (
                <div>
                    <AGTAbleWrapper>
                        <thead>
                            <tr>
                                <th>License Plate</th>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Source</th>
                                <th>Valid Until</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitors.map((visitor: Visitor) => (
                                <VisitorCardAndForm visitor={visitor} key={visitor.pk} editVisitor={openModal} />
                            ))}
                        </tbody>
                        {totalPages > 1 && (
                            <tfoot>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Pagination
                                                currentPage={Number(filters.page)}
                                                onPageChange={setPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </AGTAbleWrapper>
                </div>
            ) : (
                <h5>No visitors found</h5>
            )}

            <Modal ref={visitorFormModalRef} title={currentVisitor ? 'Edit Visitor' : 'Add Visitor'}>
                <VisitorForm
                    visitor={currentVisitor}
                    addVisitor={onAddVisitor}
                    editVisitor={onEditVisitor}
                    deleteVisitor={onDeleteVisitor}
                    visitorCodes={formattedVisitorCodes}
                />
            </Modal>
        </div>
    );
}

export default VisitorsModule;
