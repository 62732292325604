import env from 'env';
import ValetSessionClient from './ValetSessionClient';
import ReportsClient from './ReportsClient';
import AuthClient from './AuthClient';
import TimelineClient from './TimelineClient';
import SlotClient from './SlotClient';
import DiscountCodeClient from './DiscountCodeClient';
import DownloadClient from './DownloadClient';
import TenantsParkingClient from './TenantsParkingClient';
import TicketClient from './TicketClient';
import VisitorClient from './VisitorClient';
import CarClient from './CarClient';
import RatesCalendarClient from './RateClient';
import EventClient from './EventClient';
import VisitorCodeClient from './VisitorCodeClient';
import ExperimentsCalendarClient from './ExperimentClient';
import TrackingClient from './TrackingClient';
import PayoutsClient from './PayoutsClient';

const baseUrl = new URL(env.API_BASE);
const baseOrigin = new URL(baseUrl.origin);
const enforcementBaseUrl = new URL(env.API_BASE.replace('/api/', '/enforcements/'));

export const valetSessionClient = new ValetSessionClient({ baseUrl });
export const reportClient = new ReportsClient({ baseUrl });
export const authClient = new AuthClient({ baseUrl: baseOrigin });
export const timelineClient = new TimelineClient({ baseUrl });
export const ticketClient = new TicketClient({ baseUrl: enforcementBaseUrl });
export const slotClient = new SlotClient({ baseUrl });
export const discountCodeClient = new DiscountCodeClient({ baseUrl });
export const downloadClient = new DownloadClient({ baseUrl: baseOrigin });
export const tenantsParkingClient = new TenantsParkingClient({ baseUrl });
export const visitorClient = new VisitorClient({ baseUrl });
export const carClient = new CarClient({ baseUrl });
export const ratesCalendarClient = new RatesCalendarClient({ baseUrl });
export const experimentsCalendarClient = new ExperimentsCalendarClient({ baseUrl });
export const eventClient = new EventClient({ baseUrl });
export const visitorCodesClient = new VisitorCodeClient({ baseUrl });
export const trackingClient = new TrackingClient({ baseUrl });
export const payoutsClient = new PayoutsClient({ baseUrl });
