import { Errors } from 'components/ValetDashboard/utils/errors';
import useErrorMessage from 'hooks/useErrorMessages';
import React, { useEffect, useRef } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { KEYS } from './constants';

interface CodeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    codeLength: number;
    inputmode: 'numeric' | 'text';
    name: string;
    errors: Errors;
    label: string;
    icon?: React.ReactNode;
    onCodeChange(code: string): void;
}

function CodeInput({
    codeLength,
    inputmode,
    name,
    errors,
    icon,
    label,
    onCodeChange,
    ...restProps
}: CodeInputProps): JSX.Element {
    const inputs = useRef<HTMLInputElement[]>([]);
    const errorMessage = useErrorMessage(errors);

    useEffect(() => {
        inputs.current[0]?.focus();
    }, []);

    function focusNextInput(i: number) {
        setTimeout(() => {
            if (i < inputs.current.length - 1) inputs.current[i + 1].focus();
        }, 0);
    }

    function focusPreviousInput(i: number) {
        setTimeout(() => {
            if (i > 0) inputs.current[i - 1].focus();
        }, 0);
    }

    function formatValue(value: string) {
        if (inputmode === 'numeric') return value.replace(/\D/g, '');
        if (inputmode === 'text') return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        return value;
    }

    function setCharacter(value: string, i: number) {
        const input = inputs.current[i];
        if (!input) return;
        const formattedValue = formatValue(value);
        if (formattedValue.length === 0) return;
        input.value = formattedValue;
        focusNextInput(i);
        onCodeChange(inputs.current.reduce((p, c) => p + c.value, ''));
    }

    function pasteClipboardValue(text: string) {
        text.split('').forEach((char, i) => {
            setCharacter(char, i);
        });
    }

    function handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
        e.preventDefault();
        const pastedNumber = e.clipboardData?.getData('text');
        if (pastedNumber) pasteClipboardValue(pastedNumber);
    }

    function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
        const input = e.currentTarget;
        if (input.value) {
            input.setSelectionRange(0, input.value.length);
        }
    }

    function handleKeyDown(i: number) {
        return async (e: React.KeyboardEvent<HTMLInputElement>) => {
            const input = inputs.current[i];
            if (!input || !e) return;

            if ((e.metaKey || e.ctrlKey) && e.key === KEYS.V) {
                const pastedNumber = await navigator.clipboard.readText();
                if (pastedNumber) pasteClipboardValue(pastedNumber);
                return;
            }

            switch (e.key) {
                case KEYS.BACKSPACE:
                    if (input.value !== '') {
                        input.value = '';
                    }
                    focusPreviousInput(i);
                    onCodeChange(inputs.current.reduce((p, c) => p + c.value, ''));
                    break;
                case KEYS.ARROW_RIGHT:
                    focusNextInput(i);
                    break;
                case KEYS.ARROW_LEFT:
                    focusPreviousInput(i);
                    break;
                case KEYS.TAB:
                    focusNextInput(i);
                    break;
                case KEYS.ENTER:
                    input.blur();
                    break;
                default:
                    if (e.key?.length === 1) setCharacter(e.key, i);
                    break;
            }
        };
    }

    return (
        <div className="codeInputWrapper">
            <div className="label-wrapper">
                {icon && <div className="icon-wrapper">{icon}</div>}
                <label className="select-label">{label}</label>
            </div>
            <div
                className={`codeInput ${errorMessage ? 'error' : ''}`}
                style={{ gridTemplateColumns: `repeat(${codeLength}, 1fr)` }}
            >
                {Array.from({ length: codeLength }).map((_, i) => (
                    <input
                        key={i}
                        type="text"
                        inputMode={inputmode}
                        maxLength={1}
                        name={`${name}.${i}`}
                        autoComplete="off"
                        onKeyDown={handleKeyDown(i)}
                        onFocus={handleFocus}
                        onPaste={handlePaste}
                        ref={(el) => {
                            if (el) inputs.current[i] = el;
                        }}
                        {...restProps}
                    />
                ))}
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
}

export default CodeInput;
