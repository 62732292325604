import React from 'react';
import { Organization } from 'types';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import Button from 'components/Button/Button';
import TotalFuturePayout from './TotalFuturePayout';
import PayoutsTable from './PayoutsTable';
import PayoutAccountInfo from './PayoutAccountInfo';
import styles from './Payouts.module.css';
import { downloadClient } from 'clients';

type PayoutAccountInfoProps = {
    organization: Organization;
};

function PayoutsView({ organization }: PayoutAccountInfoProps) {
    function downloadPayouts() {
        downloadClient.downloadPayouts(organization.uuid);
    }

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.box}>
                    <PayoutAccountInfo organization={organization} />
                </div>
                <div className={styles.box}>
                    <TotalFuturePayout organization={organization} />
                </div>
            </div>
            <Button
                disabled={false}
                color="primary"
                onClick={() => downloadPayouts()}
                className={styles.button}
                size="sm"
            >
                <DownloadIcon /> Download
            </Button>

            <PayoutsTable organization={organization} />
        </div>
    );
}

export default PayoutsView;
