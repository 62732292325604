import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePayouts } from 'contexts/PayoutsContext';
import { Organization } from 'types';
import { AccountInfoPayload } from 'clients/PayoutsClient';
import AGMessage from 'components/AGMessage/AGMessage';
import Button from 'components/Button/Button';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import Modal, { ModalRef } from 'components/Modal/Modal';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import Loader from 'components/Loader/Loader';
import { dobFormatter, dobValidator } from './utils';
import styles from './Payouts.module.css';

type PayoutAccountInfoProps = {
    organization: Organization;
};

type EditModalOptions = 'bank' | 'org' | 'representative';
type Inputs = AccountInfoPayload & { firstName?: string; lastName?: string };

function PayoutAccountInfo({ organization }: PayoutAccountInfoProps) {
    const [inputs, setInputs] = useState<Inputs>({});
    const [editModalType, setEditModalType] = useState<EditModalOptions>('bank');
    const {
        payoutAccountInfo,
        addPayoutAccountInfo,
        getPayoutAccountInfo,
        accountInfoErrorMessage: errorMessage,
        accountInfoLoading: loading,
    } = usePayouts();

    const editModalRef = useRef<ModalRef>();

    useEffect(() => {
        getPayoutAccountInfo(organization.pk);
    }, [getPayoutAccountInfo, organization]);

    function openModal(type: EditModalOptions) {
        setEditModalType(type);
        editModalRef.current?.openModal();
    }

    function updateAccountInfo() {
        const payload = { ...inputs };
        if (inputs.firstName && inputs.lastName) {
            payload.name = `${inputs.firstName} ${inputs.lastName}`;
            delete payload.firstName;
            delete payload.lastName;
        }
        addPayoutAccountInfo(organization.pk, payload);
        setInputs({});
        editModalRef.current?.closeModal();
    }

    const modalContent: Record<
        EditModalOptions,
        { title: string; content: React.ReactNode; disableButton: (i: Inputs) => boolean }
    > = useMemo(() => {
        return {
            bank: {
                title: 'Update Bank Account',
                disableButton: (inputs) => !inputs.routing_number || !inputs.account_number,
                content: (
                    <>
                        <TextInput
                            name="routing_number"
                            label="Routing Number"
                            onChange={(e) => {
                                e.preventDefault();
                                setInputs((i) => ({ ...i, routing_number: e.target.value }));
                            }}
                            type="number"
                            placeholder="123456789"
                            errors={null}
                            className={styles.numberInput}
                        />
                        <TextInput
                            name="account_number"
                            label="Account Number"
                            onChange={(e) => {
                                e.preventDefault();
                                setInputs((i) => ({ ...i, account_number: e.target.value }));
                            }}
                            type="number"
                            placeholder="123456789"
                            errors={null}
                            className={styles.numberInput}
                        />
                    </>
                ),
            },
            org: {
                title: 'Update Organization',
                disableButton: (inputs) => !inputs.ein,
                content: (
                    <>
                        <TextInput
                            name="ein"
                            label="Employer Identification Number (EIN)"
                            onChange={(e) => {
                                e.preventDefault();
                                setInputs((i) => ({ ...i, ein: e.target.value }));
                            }}
                            type="text"
                            placeholder="123456789"
                            errors={null}
                        />
                    </>
                ),
            },
            representative: {
                title: 'Update Account Representative',
                disableButton: (inputs) =>
                    !inputs.firstName || !inputs.lastName || !inputs.dob || !dobValidator(inputs.dob),
                content: (
                    <>
                        <p>
                            We never see or save this sensitive data. <br />
                            It is sent directly to our payment processor for identity verification.
                        </p>
                        <TextInput
                            name="firstName"
                            label="First Name"
                            onChange={(e) => {
                                e.preventDefault();
                                setInputs((i) => ({ ...i, firstName: e.target.value }));
                            }}
                            type="text"
                            placeholder="First Name"
                            errors={null}
                        />
                        <TextInput
                            name="lastName"
                            label="Last Name"
                            onChange={(e) => {
                                e.preventDefault();
                                setInputs((i) => ({ ...i, lastName: e.target.value }));
                            }}
                            type="text"
                            placeholder="Last Name"
                            errors={null}
                        />
                        <TextInput
                            name="DOB"
                            label="Date of Birth"
                            onChange={(e) => {
                                e.preventDefault();
                                const value = dobFormatter(inputs.dob || '', e.target.value);
                                setInputs((i) => ({ ...i, dob: value }));
                                e.target.value = value;
                            }}
                            type="text"
                            placeholder="MM/DD/YYY"
                            errors={null}
                        />
                    </>
                ),
            },
        };
    }, [inputs.dob]);

    return (
        <>
            <div className={styles.accountInfoContainer}>
                {errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}

                {loading || !payoutAccountInfo ? (
                    <Loader />
                ) : (
                    <>
                        <div className={styles.infoRow}>
                            <p>Bank Account</p>
                            <Button color="info" onClick={() => openModal('bank')} size="sm">
                                Edit
                            </Button>
                            <h3>{payoutAccountInfo.bank_account || '-'} </h3>
                        </div>

                        <div className={styles.infoRow}>
                            <p>Organization EIN</p>
                            <Button color="info" onClick={() => openModal('org')} size="sm">
                                Edit
                            </Button>
                            <h3>{payoutAccountInfo.has_ein ? 'EIN verified' : 'EIN not verified'} </h3>
                        </div>

                        <div className={styles.infoRow}>
                            <p>
                                Account Representative
                                <InfoTooltip text="To prevent fraud and abuse, our payment processor requires identity verification from someone who works at the organization. Information like DOB or identity documents may be needed as payout amounts increase." />
                            </p>
                            <Button color="info" onClick={() => openModal('representative')} size="sm">
                                Edit
                            </Button>
                            <h3>
                                {payoutAccountInfo.representative && payoutAccountInfo.representative !== 'None None'
                                    ? payoutAccountInfo.representative
                                    : 'N/a'}
                            </h3>
                        </div>
                    </>
                )}
            </div>

            {/* Edit modal */}
            <Modal ref={editModalRef} title={modalContent[editModalType].title}>
                <AGForm style={{ minWidth: '340px' }}>
                    {modalContent[editModalType].content}
                    <Button onClick={updateAccountInfo} disabled={modalContent[editModalType].disableButton(inputs)}>
                        Update
                    </Button>
                </AGForm>
            </Modal>
        </>
    );
}

export default PayoutAccountInfo;
