import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { DiscountCodeFormData } from './DiscountCodeModalForm';

export enum DiscountType {
    DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
    TIME_BASED = 'TIME_BASED',
}

export const discountTypeOptions: SelectOption[] = [
    {
        label: 'Dollar Amount Discount',
        value: DiscountType.DOLLAR_AMOUNT,
    },
    {
        label: 'Percentage Discount',
        value: DiscountType.PERCENTAGE,
    },
    {
        label: 'Time-based Discount',
        value: DiscountType.TIME_BASED,
    },
];

export enum ApplicableRentalTypeOptions {
    HOURLY = 'hourly',
    FLAT = 'flat rate',
    DAILY = 'daily',
    MONTHLY = 'monthly',
}

export const applicableRentalTypeOptions: SelectOption[] = [
    {
        label: 'Hourly',
        value: ApplicableRentalTypeOptions.HOURLY as string,
    },
    {
        label: 'Flat',
        value: ApplicableRentalTypeOptions.FLAT as string,
    },
    {
        label: 'Daily',
        value: ApplicableRentalTypeOptions.DAILY as string,
    },
    {
        label: 'Monthly',
        value: ApplicableRentalTypeOptions.MONTHLY as string,
    },
];

export function sanitizeDiscountValues(values: DiscountCodeFormData) {
    return {
        sanitizedDiscountAmount: values.discountAmount ? values.discountAmount.replace(/[$\s]/g, '') : '',
        sanitizedDiscountPercentage: values.discountPercentage ? values.discountPercentage.replace(/[%\s]/g, '') : '',
        sanitizedDiscountTime: values.discountTime ? values.discountTime.replace(/[\smin]/g, '') : '',
    };
}
