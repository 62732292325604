import {
    PAYOUTS_REQUESTED,
    PAYOUTS_SUCCESS,
    PAYOUTS_FAILED,
    ADD_PAYOUT_ACCOUNT_INFO_FAILURE,
    ADD_PAYOUT_ACCOUNT_INFO_REQUESTED,
    PAYOUT_ACCOUNT_INFO_FAILED,
    PAYOUT_ACCOUNT_INFO_SUCCESS,
    PAYOUT_ACCOUNT_INFO_REQUESTED,
} from './constants';
import { Action } from 'contexts/useReducerWithMiddleware';
import { Payout, PayoutAccountInfo } from 'types/Payouts';
import { PagedResponse } from 'types/Pagination';

export type PayoutsState = {
    loading: boolean;
    errorMessage: string;
    payouts: PagedResponse<Payout> | null;
    accountInfoLoading: boolean;
    accountInfoErrorMessage: string;
    payoutAccountInfo: PayoutAccountInfo | null;
};

export const initialPayoutsState: PayoutsState = {
    loading: false,
    errorMessage: '',
    payouts: null,
    accountInfoLoading: false,
    accountInfoErrorMessage: '',
    payoutAccountInfo: null,
};

export default function payoutsReducer(state = initialPayoutsState, action: Action): PayoutsState {
    switch (action.type) {
        case PAYOUTS_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case PAYOUTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payouts: (action.payload as { payouts: PagedResponse<Payout> }).payouts,
            };
        case PAYOUTS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        case PAYOUT_ACCOUNT_INFO_REQUESTED:
        case ADD_PAYOUT_ACCOUNT_INFO_REQUESTED:
            return {
                ...state,
                accountInfoLoading: true,
                accountInfoErrorMessage: '',
            };
        case PAYOUT_ACCOUNT_INFO_SUCCESS:
            return {
                ...state,
                accountInfoLoading: false,
                payoutAccountInfo: (action.payload as { payoutAccountInfo: PayoutAccountInfo }).payoutAccountInfo,
            };
        case PAYOUT_ACCOUNT_INFO_FAILED:
        case ADD_PAYOUT_ACCOUNT_INFO_FAILURE:
            return {
                ...state,
                accountInfoLoading: false,
                accountInfoErrorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialPayoutsState;
    }
}
