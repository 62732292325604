import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, Tooltip, Legend, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { AggregateEntry } from 'types';

ChartJS.register(BarElement, Tooltip, Legend);

type BarGraphProps = {
    report: AggregateEntry[];
};

function AggregatorGraph({ report }: BarGraphProps) {
    const labels = [];

    const chartRef = useRef<ChartJS<'bar'>>(null);

    const airGarageDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#D1581F',
        label: 'AirGarage',
    };
    const spotHeroDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#50ACC6',
        label: 'SpotHero',
    };
    const cheapAirportDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#004865',
        label: 'CheapAirportParking',
    };
    const airportDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#079183',
        label: 'Airport Parking',
    };
    const parkwhizDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#4EC059',
        label: 'Parkwhiz',
    };
    const parkwayDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#E5B440',
        label: 'Parkway',
    };
    const nosonDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: '#CCB580',
        label: 'Noson',
    };

    for (const dayReport of report) {
        labels.push(dayReport.short_date);

        airGarageDataset.data.push(dayReport.airgarage);
        spotHeroDataset.data.push(dayReport.spothero || 0);
        cheapAirportDataset.data.push(dayReport.cheap_airport || 0);
        airportDataset.data.push(dayReport.airport_parking || 0);
        parkwhizDataset.data.push(dayReport.parkwhiz || 0);
        parkwayDataset.data.push(dayReport.parkway || 0);
        nosonDataset.data.push(dayReport.noson || 0);
    }

    const data: ChartData<'bar', number[], string> = {
        labels,
        datasets: [
            airGarageDataset,
            spotHeroDataset,
            cheapAirportDataset,
            airportDataset,
            parkwhizDataset,
            parkwayDataset,
            nosonDataset,
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            bar: {
                borderRadius: 2,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: { display: false, drawBorder: false },
                ticks: {
                    autoSkipPadding: 40,
                    align: 'start',
                },
            },
            y: {
                stacked: true,
                grid: { drawBorder: false, borderDash: [2] },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                },
            },
        },
    };

    return <Bar ref={chartRef} data={data} options={options} style={{ maxHeight: 380 }} />;
}

export default AggregatorGraph;
