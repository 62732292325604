import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { AppState } from 'reducers/reducers';
import { selectedSpotId } from 'selectors';
import { AggregatorReportProvider } from 'contexts/AggregatorReportContex';
import AggregatorRentals from 'components/AggregatorRentals/AggregatorRentals';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';
import useFilters from 'hooks/useFilters';
import { isValidDateForReports } from 'utils/helpers';

type RentalsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function AggregatorReportContainer(props: RentalsModuleContainerProps) {
    const monthYearUtil = new MonthYearUtil();
    const { push: connectedPush, selectedSpot } = props;

    const { filters, urlUpdater } = useFilters<{ monthYear: MonthYear['value'] }>({
        filterValidators: {
            monthYear: function dateValidator(currentValue: string | null): string {
                return isValidDateForReports(currentValue) ? currentValue : monthYearUtil.currentMonthYear.value;
            },
        },
        push: connectedPush,
    });

    function handleDateFilterSelected(value?: string): void {
        urlUpdater.monthYear(value ? value : monthYearUtil.currentMonthYear.value);
    }

    return (
        <AggregatorReportProvider>
            <AggregatorRentals
                filters={filters}
                handleDateFilterSelected={handleDateFilterSelected}
                spotPk={selectedSpot}
            />
        </AggregatorReportProvider>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
    };
}

const mapDispatchToProps = {
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatorReportContainer);
