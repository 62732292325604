import React from 'react';
import { Organization } from 'types';
import InfoIcon from 'components/Icons/InfoIcon';
import styles from './Payouts.module.css';

function TotalFuturePayout({ organization: { payout_schedule, owed_funds } }: { organization: Organization }) {
    return (
        <div className={styles.totalPayout}>
            <h3>Total Future Payout</h3>
            <h1>{owed_funds}</h1>
            <p>
                <InfoIcon />{' '}
                {payout_schedule
                    ? `Payouts occur ${payout_schedule} and may take a 3-5 days to complete.`
                    : `Payouts occur by the 7th of each month for the previous month's revenue`}
            </p>
        </div>
    );
}

export default TotalFuturePayout;
