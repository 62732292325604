import { PaginatedPromoCodes } from 'types';
import APIClient from './APIClient';

type AddPromoCodeInput = {
    code: string;
    spot: number;
    value: number | null;
    percentage_discount: string | null;
    applicable_rental_types: string[];
    minutes_to_discount: number | null;
    number_of_uses: string | null;
    expiration: Date | null;
    notes?: string;
    deleted?: boolean;
};

class PromoCodeService {
    getPromoCodes(
        spot: number,
        token: string,
        page: number = 1,
        usableCodes: boolean = true
    ): Promise<PaginatedPromoCodes> {
        return APIClient.get(`promo_codes/?spot=${spot}&page=${page}&usable_codes=${usableCodes}`, token);
    }
    addPromoCode(promoCode: AddPromoCodeInput, token: string) {
        return APIClient.post(`promo_codes/`, promoCode, token);
    }
    deletePromoCode(promoCodePk: number, token: string) {
        return APIClient.delete(`promo_codes/${promoCodePk}/`, token);
    }
}

export default new PromoCodeService();
