import { put, takeEvery, select } from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'connected-react-router';
import amplitude from 'amplitude-js';
import env from 'env';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_REQUESTED,
    PROFILE_REQUESTED,
    PROFILE_SUCCESS,
    PROFILE_FAILED,
    ORGANIZATION_LIST_REQUESTED,
    ORGANIZATION_LIST_FAILED,
    ORGANIZATION_LIST_SUCCESS,
    SPOT_LIST_REQUESTED,
    SPOT_LIST_FAILED,
    SPOT_LIST_SUCCESS,
    SPOT_PRICING_REQUESTED,
    SPOT_PRICING_SUCCESS,
    SPOT_PRICING_FAILED,
    ORGANIZATION_REQUESTED,
    ORGANIZATION_SUCCESS,
    ORGANIZATION_SPOTS_REQUESTED,
    ORGANIZATION_SPOTS_SUCCESS,
    ORGANIZATION_SPOTS_FAILED,
    SET_AUTH_TOKEN,
    TEAM_REQUESTED,
    TEAM_SUCCESS,
    TEAM_FAILED,
    ADD_TEAM_REQUESTED,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAILED,
    DELETE_TEAM_FAILED,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_REQUESTED,
    REPORTS_REQUESTED,
    REPORTS_FAILED,
    REPORTS_SUCCESS,
    PROMO_CODE_REPORTS_REQUESTED,
    PROMO_CODE_REPORTS_FAILED,
    PROMO_CODE_REPORTS_SUCCESS,
    ENFORCEMENTS_REQUESTED,
    ENFORCEMENTS_FAILED,
    ENFORCEMENTS_SUCCESS,
    UPDATE_ENFORCEMENT_REQUESTED,
    UPDATE_ENFORCEMENT_FAILED,
    UPDATE_ENFORCEMENT_SUCCESS,
    ADD_ENFORCEMENT_PENALTY_REQUESTED,
    ADD_ENFORCEMENT_PENALTY_FAILED,
    ADD_ENFORCEMENT_PENALTY_SUCCESS,
    DAILY_REPORTS_REQUESTED,
    DAILY_REPORTS_FAILED,
    DAILY_REPORTS_SUCCESS,
    ADD_ORGANIZATION_REQUESTED,
    ADD_ORGANIZATION_SUCCESS,
    ADD_ORGANIZATION_FAILED,
    UPLOAD_SPOT_PHOTO_REQUESTED,
    UPLOAD_SPOT_PHOTO_FAILED,
    UPLOAD_SPOT_PHOTO_SUCCESS,
    UPLOAD_LOGO_REQUESTED,
    UPLOAD_LOGO_FAILED,
    UPLOAD_LOGO_SUCCESS,
    ADD_SPOT_REQUESTED,
    ADD_SPOT_FAILED,
    ADD_SPOT_SUCCESS,
    SET_ORGANIZATION,
    CLEAR_DATA,
    REFUNDS_REQUESTED,
    ISSUE_REFUND_REQUESTED,
    REFUNDS_SUCCESS,
    ISSUE_REFUND_SUCCESS,
    ISSUE_REFUND_FAILED,
    REFUNDS_FAILED,
    IGNORE_REFUND_REQUESTED,
    IGNORE_REFUND_SUCCESS,
    IGNORE_REFUND_FAILED,
    SALES_REPS_REQUESTED,
    SALES_REPS_SUCCESS,
    SALES_REPS_FAILED,
    SEND_VERIFICATION_CODE_REQUESTED,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_FAILED,
    PROMO_CODES_REQUESTED,
    PROMO_CODES_SUCCESS,
    PROMO_CODES_FAILED,
    ADD_PROMO_CODE_REQUESTED,
    ADD_PROMO_CODE_FAILED,
    ADD_PROMO_CODE_SUCCESS,
    DELETE_PROMO_CODE_REQUESTED,
    DELETE_PROMO_CODE_FAILED,
    DELETE_PROMO_CODE_SUCCESS,
    VISITORS_TO_VALIDATE_REQUESTED,
    VISITORS_TO_VALIDATE_SUCCESS,
    VISITORS_TO_VALIDATE_FAILED,
    ADD_VISITOR_TO_VALIDATE_REQUESTED,
    ADD_VISITOR_TO_VALIDATE_SUCCESS,
    ADD_VISITOR_TO_VALIDATE_FAILED,
    VALIDATE_VISITOR_REQUESTED,
    VALIDATE_VISITOR_SUCCESS,
    VALIDATE_VISITOR_FAILED,
    LOGOUT,
    UPLOAD_VISITOR_CSV_REQUESTED,
    UPLOAD_VISITOR_CSV_SUCCESS,
    UPLOAD_VISITOR_CSV_FAILED,
    PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED,
    PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS,
    PROGRESS_UPLOAD_VISITOR_CSV_FAILED,
    CURRENTLY_PARKED_REQUESTED,
    CURRENTLY_PARKED_SUCCESS,
    CURRENTLY_PARKED_FAILED,
    CHECK_PLATE_REQUESTED,
    CHECK_PLATE_SUCCESS,
    CHECK_PLATE_FAILED,
    SPOT_MULTIDAY_PRICING_SUCCESS,
    SPOT_MULTIDAY_PRICING_FAILED,
    SPOT_MULTIDAY_PRICING_REQUESTED,
    UPDATE_REPORTS_REQUESTED,
    UPDATE_REPORTS_SUCCESS,
    UPDATE_REPORTS_FAILED,
    GET_TICKET_REQUESTED,
    GET_TICKET_FAILED,
    GET_TICKET_SUCCESS,
    WAIVE_TICKET_REQUESTED,
    WAIVE_TICKET_SUCCESS,
    WAIVE_TICKET_FAILED,
} from 'actions/constants';
import { tokenState, selectedOrganizationId, selectedSpotId } from 'selectors';
import ProfileService from 'services/ProfileService';
import SpotsService from 'services/SpotsService';
import ReportsService from 'services/ReportsService';
import EnforcementsService from 'services/EnforcementsService';
import VisitorService from 'services/VisitorService';
import TeamService from 'services/TeamService';
import OrganizationService from 'services/OrganizationService';
import UploadService from 'services/UploadService';
import RefundService from 'services/RefundService';
import PromoCodeService from 'services/PromoCodeService';
import ValidationRequestsService from 'services/ValidationRequestsService';
import { SpotInterface } from 'types';
import { PromoCodeFormInputs } from 'components/Dashboard/PromoCodeForm/PromoCodeForm';
import { ticketClient } from '../clients';
import { HTTPError } from 'clients/HTTPClient';

function assert(condition: boolean, failure_message = 'Invalid information provided') {
    if (!condition) {
        throw Error(failure_message);
    }
}

function* loginProfile({ payload }: any) {
    try {
        const { username, password } = payload;
        const token = yield ProfileService.getToken(username, password);
        assert(token, 'Invalid username or password');
        yield put({ type: LOGIN_SUCCESS, payload: { token: token } });
        yield put(push('/'));
    } catch (error) {
        yield put({ type: LOGIN_FAILED, message: error.toString() || 'Failed to login' });
    }
}

function* sendVerificationCode({ payload }: any) {
    try {
        const { username, phoneCode } = payload;
        const response = yield ProfileService.sendVerificationCode(username, phoneCode);
        assert(!response.error, response.error);
        yield put({ type: SEND_VERIFICATION_CODE_SUCCESS, payload: { username: username } });
    } catch (error) {
        yield put({
            type: SEND_VERIFICATION_CODE_FAILED,
            message: error.toString() || 'Failed to send a verification code',
        });
    }
}

function* getProfile() {
    yield put({ type: SET_AUTH_TOKEN });
    try {
        const token = yield select(tokenState);
        const profile = yield ProfileService.getProfileInfo(token);
        assert(profile.pk > 0);
        yield put({ type: PROFILE_SUCCESS, payload: { profile: profile } });
        if (profile.org_type === 'parking' || profile.superuser) {
            // Let login
        } else if (profile.is_renter) {
            window.location.assign(env.WEBPAY_DOMAIN);
        } else if (profile.org_type === 'towing') {
            yield put({ type: LOGOUT });
        }
    } catch {
        yield put({ type: PROFILE_FAILED });
        yield put(push('/login'));
    }
}

function* getSalesReps() {
    try {
        const token = yield select(tokenState);
        const salesReps = yield ProfileService.getAirGarageProfiles(token);
        yield put({ type: SALES_REPS_SUCCESS, payload: { salesReps: salesReps } });
    } catch (error) {
        yield put({ type: SALES_REPS_FAILED, message: error.toString() || 'Could not fetch sales reps' });
    }
}

function* getOrganization({ payload: { orgId } }: any) {
    const token = yield select(tokenState);
    const organization = yield OrganizationService.getOrganization(orgId, token);
    yield put({ type: ORGANIZATION_SUCCESS, payload: { organization: organization } });
}

function* getOrganizations() {
    const token = yield select(tokenState);
    try {
        const organizations = yield OrganizationService.getOrganizations(token);
        yield put({ type: ORGANIZATION_LIST_SUCCESS, payload: { organizations: organizations } });
    } catch (error) {
        yield put({ type: ORGANIZATION_LIST_FAILED, message: 'Cannot retrieve orgs' });
    }
}

function* getSpotList() {
    const token = yield select(tokenState);
    try {
        const spotList = yield SpotsService.getSpots(token);
        yield put({ type: SPOT_LIST_SUCCESS, payload: { spotList: spotList } });
    } catch (error) {
        yield put({ type: SPOT_LIST_FAILED, message: 'Cannot retrieve spots' });
    }
}

function* getSpots() {
    const token = yield select(tokenState);
    const organizationId = yield select(selectedOrganizationId);
    try {
        const spots = yield SpotsService.getSpotsInfo(organizationId, token);
        const spotState: { [key: string]: SpotInterface } = {};
        for (let i = 0; i < spots.length; i++) {
            spotState[spots[i].pk] = spots[i];
        }
        yield put({ type: ORGANIZATION_SPOTS_SUCCESS, payload: { spots: spotState } });
    } catch (error) {
        yield put({ type: ORGANIZATION_SPOTS_FAILED, message: error.toString() || 'Could not fetch spots' });
    }
}

function* getSpotPricing() {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    try {
        const pricing = yield SpotsService.getPrices(spotId, token);
        yield put({ type: SPOT_PRICING_SUCCESS, payload: { pricing: pricing.result } });
    } catch (error) {
        yield put({ type: SPOT_PRICING_FAILED, message: error.message });
    }
}

function* getSpotMultidayPricing({ payload }: any) {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const { days } = payload as { days: number };
    try {
        const pricing = yield SpotsService.getMultidayPrices(spotId, days, token);
        yield put({ type: SPOT_MULTIDAY_PRICING_SUCCESS, payload: { pricing: pricing.result } });
    } catch (error) {
        yield put({ type: SPOT_MULTIDAY_PRICING_FAILED, message: error.message || 'Something went wrong' });
    }
}

function* getReports({ payload }: any) {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const { filters } = payload as { filters?: { date: { month: number; year: number } } };
    try {
        const reports = yield ReportsService.getReports(spotId, filters, token);
        yield put({ type: REPORTS_SUCCESS, payload: { reports: reports }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: REPORTS_FAILED, message: error.toString() || 'Could not fetch reports' });
    }
}

function* updateReports() {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    try {
        const reports = yield ReportsService.getReports(spotId, undefined, token);
        yield put({ type: UPDATE_REPORTS_SUCCESS, payload: { reports: reports }, selectedSpotID: spotId });
    } catch (error) {
        console.log('Failed to update reports');
        yield put({ type: UPDATE_REPORTS_FAILED });
    }
}

function* getPromoCodeReports({ payload }: any) {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const { filters } = payload;
    try {
        const promoCodeReports = yield ReportsService.getPromoCodeReports(spotId, filters, token);
        yield put({
            type: PROMO_CODE_REPORTS_SUCCESS,
            payload: { promoCodeReports: promoCodeReports },
            selectedSpotID: spotId,
        });
    } catch (error) {
        yield put({ type: PROMO_CODE_REPORTS_FAILED, message: error.toString() || 'Could not fetch reports' });
        console.log(error);
    }
}

function* getEnforcements({ payload }: any) {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const { filtered_url } = payload;
    try {
        const enforcements = yield EnforcementsService.getEnforcements(spotId, filtered_url, token);
        yield put({ type: ENFORCEMENTS_SUCCESS, payload: { enforcements: enforcements }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: ENFORCEMENTS_FAILED, message: error.toString() || 'Could not fetch enforcements' });
    }
}

function* getCurrentlyParked({ payload }: any) {
    const { violatorsOnly } = payload;
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    try {
        const records = yield EnforcementsService.getCurrentlyParked(spotId, violatorsOnly, token);
        yield put({ type: CURRENTLY_PARKED_SUCCESS, payload: { records: records }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: CURRENTLY_PARKED_FAILED, message: error.toString() || 'Could not fetch currently parked' });
    }
}

function* checkPlate({ payload }: any) {
    const { plate } = payload;
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const data = { spot: spotId, plate: plate, autostart_disabled: true, source: 'Website' };
    try {
        const result = yield EnforcementsService.checkPlate(data, token);
        yield put({ type: CHECK_PLATE_SUCCESS, payload: { result: result }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: CHECK_PLATE_FAILED, message: error.toString() || 'Could not fetch results for that plate.' });
    }
}

function* updateEnforcement({ payload }: any) {
    try {
        const token = yield select(tokenState);
        const spotId = yield select(selectedSpotId);
        const enforcementKey = payload.pk;
        const violatorsOnly = true;
        const profile = yield ProfileService.getProfileInfo(token);
        const data = { ...payload.body, owner: profile.pk };
        yield EnforcementsService.updateEnforcement(enforcementKey, data, token);
        const enforcements = yield EnforcementsService.getCurrentlyParked(spotId, violatorsOnly, token);
        yield put({
            type: UPDATE_ENFORCEMENT_SUCCESS,
            payload: { enforcements: enforcements },
            selectedSpotID: spotId,
        });
    } catch (error) {
        yield put({ type: UPDATE_ENFORCEMENT_FAILED, message: error.toString() || 'Failed' });
    }
}

function* addEnforcementPenalty({ payload }: any) {
    try {
        const token = yield select(tokenState);
        const spotId = yield select(selectedSpotId);
        try {
            const profile = yield ProfileService.getProfileInfo(token);
            const data = { ...payload.body, owner: profile.pk, source: 'Website' };
            const result = yield EnforcementsService.addEnforcementPenalty(data, token);
            yield put({ type: ADD_ENFORCEMENT_PENALTY_SUCCESS, payload: { result: result }, selectedSpotID: spotId });
        } catch (error) {
            yield put({ type: ADD_ENFORCEMENT_PENALTY_FAILED, message: 'No auth' });
        }
    } catch (error) {
        yield put({ type: UPDATE_ENFORCEMENT_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getDailyReports({ payload }: any) {
    const token = yield select(tokenState);
    const spotId = yield select(selectedSpotId);
    const { filters } = payload;
    try {
        const reports = yield ReportsService.getDailyReports(spotId, filters, token);
        yield put({ type: DAILY_REPORTS_SUCCESS, payload: { reports: reports }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: DAILY_REPORTS_FAILED, message: error.toString() || 'Could not fetch reports' });
    }
}

function* getTeam({ payload }: any) {
    const { id } = payload;
    const token = yield select(tokenState);
    if (id) {
        const invitations = yield TeamService.getTeam(token, id);
        yield put({ type: TEAM_SUCCESS, payload: { invitations: invitations } });
    } else {
        yield put({ type: TEAM_FAILED, message: 'Cannot find team' });
    }
}

function* addTeamMember({ payload }: any) {
    const { invitation } = payload;
    const token = yield select(tokenState);
    const organizationId = yield select(selectedOrganizationId);
    invitation.organization = organizationId;
    try {
        amplitude.getInstance().logEvent('Added team member');
        yield TeamService.sendInvite(invitation, token);
        const invitations = yield TeamService.getTeam(token, organizationId);
        assert(Array.isArray(invitations));
        yield put({ type: ADD_TEAM_SUCCESS, payload: { invitations: invitations } });
    } catch (error) {
        yield put({ type: ADD_TEAM_FAILED, message: error.toString() || 'Could not fetch team' });
    }
}

function* deleteTeamMember({ payload }: any) {
    const { invitation_id } = payload;
    const token = yield select(tokenState);
    const organizationId = yield select(selectedOrganizationId);
    try {
        amplitude.getInstance().logEvent('Deleted team member');
        yield TeamService.deleteInvite(invitation_id, token);
        const invitations = yield TeamService.getTeam(token, organizationId);
        yield put({ type: DELETE_TEAM_SUCCESS, payload: { invitations: invitations } });
    } catch (error) {
        yield put({ type: DELETE_TEAM_FAILED, message: error.toString() || 'Could not fetch team' });
    }
}

function* getTicket({ payload }: any) {
    const { identifier } = payload;
    try {
        amplitude.getInstance().logEvent('Get ticket');
        let ticket = null;
        try {
            ticket = yield ticketClient.getTicket(identifier.toUpperCase());
        } catch (error) {
            const tickets = (yield ticketClient.getTicketByPlate(identifier.toUpperCase())) as any[];
            if (tickets.length === 0) {
                throw new Error('Could not find that violation');
            }
            ticket = tickets[0];
        }
        yield put({ type: GET_TICKET_SUCCESS, payload: { ticket } });
    } catch (error) {
        if (error instanceof HTTPError) {
            yield put({ type: GET_TICKET_FAILED, message: error.cause.detail });
        } else {
            yield put({ type: GET_TICKET_FAILED, message: error.toString() || 'Could not find that violation' });
        }
    }
}

function* waiveTicket({ payload }: any) {
    const { identifier } = payload;
    try {
        amplitude.getInstance().logEvent('Waive ticket');
        const ticket = yield ticketClient.waiveTicket(identifier);
        yield put({ type: WAIVE_TICKET_SUCCESS, payload: { ticket: ticket } });
    } catch (error) {
        yield put({ type: WAIVE_TICKET_FAILED, message: error.toString() || 'Could not find ticket' });
    }
}

function* addOrganization({ payload }: any) {
    try {
        const { organization } = payload;
        const token = yield select(tokenState);
        const org = yield OrganizationService.addOrganization(organization, token);
        yield put({ type: ADD_ORGANIZATION_SUCCESS, payload: { organization: org } });
        yield put({ type: CLEAR_DATA });
        yield put({ type: SET_ORGANIZATION, payload: { organization: org.pk } });
        yield put(push('/'));
    } catch (error) {
        yield put({ type: ADD_ORGANIZATION_FAILED, message: error.toString() || 'Could not add org' });
    }
}

function* uploadSpotPhoto({ payload }: any) {
    try {
        const { file } = payload;
        const token = yield select(tokenState);
        const { result } = yield UploadService.uploadFile(file, token);
        yield put({ type: UPLOAD_SPOT_PHOTO_SUCCESS, payload: { url: result } });
    } catch (error) {
        yield put({ type: UPLOAD_SPOT_PHOTO_FAILED, message: error.toString() || 'Could not upload spot photo' });
    }
}

function* uploadLogo({ payload }: any) {
    try {
        const { file } = payload;
        const token = yield select(tokenState);
        const { result } = yield UploadService.uploadFile(file, token);
        yield put({ type: UPLOAD_LOGO_SUCCESS, payload: { url: result } });
    } catch (error) {
        yield put({ type: UPLOAD_LOGO_FAILED, message: error.toString() || 'Could not upload logo' });
    }
}

function* uploadVisitorsCSV({ payload }: any) {
    try {
        const { data } = payload;
        const token = yield select(tokenState);
        const { result } = yield UploadService.uploadFile(data.file, token);
        data['csv_url'] = result;
        const response = yield VisitorService.uploadVisitorsCSV(data, token);
        const body = yield response.json();
        if (response.ok) {
            const message = body.message;
            yield put({ type: UPLOAD_VISITOR_CSV_SUCCESS, message });
            amplitude.getInstance().logEvent('Uploaded visitor CSV file');
        } else {
            const message = body[0];
            yield put({ type: UPLOAD_VISITOR_CSV_FAILED, message });
        }
    } catch (error) {
        yield put({ type: UPLOAD_VISITOR_CSV_FAILED, message: error || 'Could not upload CSV' });
        amplitude.getInstance().logEvent('Uploaded visitor CSV file failed');
    }
}

function* uploadVisitorsCSVProgress() {
    try {
        const token = yield select(tokenState);
        const spot = yield select(selectedSpotId);
        if (!spot) return;
        const response = yield VisitorService.progressUploadVisitorsCSV(spot, token);
        yield put({ type: PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS, payload: response.progress });
    } catch (error) {
        yield put({
            type: PROGRESS_UPLOAD_VISITOR_CSV_FAILED,
            message: error.toString() || 'Could not check progress of upload CSV',
        });
        amplitude.getInstance().logEvent('Check progress of visitor CSV upload failed');
    }
}

function* addSpot({ payload }: any) {
    try {
        const { spot } = payload;
        const organization = yield select(selectedOrganizationId);
        const token = yield select(tokenState);
        spot.services_agreement_date = moment(spot.services_agreement_date).unix();

        const data = {
            organization,
            ...spot,
            price_hourly: spot.price_hourly && spot.price_hourly * 100,
            price_daily: spot.price_daily && spot.price_daily * 100,
            price_monthly: spot.price_monthly && spot.price_monthly * 100,
            daily_max: spot.daily_max && spot.daily_max * 100,
            spot_operation: {
                tier: spot.tier,
                source: spot.source,
                services_agreement_date: spot.services_agreement_date,
            },
            spot_service: {
                lpr_service: spot.lpr_service,
                cleaning_service: spot.cleaning_service,
                security_service: spot.security_service,
                snow_service: spot.snow_service,
                utilities_service: spot.utilities_service,
            },
            deal_structure: {
                account_executive: spot.account_executive,
                sales_development_representative: spot.sales_development_representative,
                deal_type: spot.deal_type,
                lease_amount: spot.lease_amount,
                hourly_commission: spot.hourly_commission,
                daily_commission: spot.daily_commission,
                monthly_commission: spot.monthly_commission,
            },
        };

        yield SpotsService.addSpot(data, token);
        yield put({ type: ADD_SPOT_SUCCESS });
        yield put({ type: ORGANIZATION_SPOTS_REQUESTED });
        yield put(push('/'));
    } catch (error) {
        yield put({ type: ADD_SPOT_FAILED, errorMessage: error.toString() || 'Could not create spot' });
    }
}

function* getRefunds() {
    try {
        const token = yield select(tokenState);
        const refunds = yield RefundService.getRefunds(token);
        yield put({ type: REFUNDS_SUCCESS, payload: { refunds: refunds } });
    } catch (error) {
        yield put({ type: REFUNDS_FAILED, message: error.toString() || 'Failed' });
    }
}

function* issueRefund({ payload }: any) {
    try {
        const { refund } = payload;
        const token = yield select(tokenState);
        const response = yield RefundService.issueRefund(refund, token);
        assert(!response.error, response.error);
        const refunds = yield RefundService.getRefunds(token);
        yield put({ type: ISSUE_REFUND_SUCCESS, payload: { refunds: refunds } });
    } catch (error) {
        yield put({ type: ISSUE_REFUND_FAILED, message: error.toString() || 'Failed' });
    }
}

function* ignoreRefund({ payload }: any) {
    try {
        const { refund } = payload;
        const token = yield select(tokenState);
        yield RefundService.ignoreRefund(refund, token);
        const refunds = yield RefundService.getRefunds(token);
        yield put({ type: IGNORE_REFUND_SUCCESS, payload: { refunds: refunds } });
    } catch (error) {
        yield put({ type: IGNORE_REFUND_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getPromoCodes({ payload }: any) {
    try {
        const { page, usableCodes } = payload;
        const token = yield select(tokenState);
        const spotId = yield select(selectedSpotId);
        const promoCodes = yield PromoCodeService.getPromoCodes(spotId, token, page, usableCodes);
        yield put({ type: PROMO_CODES_SUCCESS, payload: { promoCodes: promoCodes }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: PROMO_CODES_FAILED, message: error.toString() || 'Failed to fetch discount codes' });
    }
}

function* addPromoCode({ payload }: any) {
    try {
        const { promoCode } = payload as { promoCode: PromoCodeFormInputs };
        let adjustedValue: number | null = null;
        if (typeof promoCode.value === 'string' && promoCode.value !== '') {
            adjustedValue = parseInt(promoCode.value) * 100;
        }
        const token = yield select(tokenState);
        const spotId = yield select(selectedSpotId);
        yield PromoCodeService.addPromoCode(
            {
                ...promoCode,
                value: adjustedValue,
                spot: spotId,
            },
            token
        );
        const promoCodes = yield PromoCodeService.getPromoCodes(spotId, token);
        yield put({ type: ADD_PROMO_CODE_SUCCESS, payload: { promoCodes: promoCodes }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: ADD_PROMO_CODE_FAILED, message: error.toString() || 'Failed to add a discount code' });
    }
}

function* deletePromoCode({ payload }: any) {
    amplitude.getInstance().logEvent('Deleted discount code');
    try {
        const { promoCodePk } = payload;
        const token = yield select(tokenState);
        const spotId = yield select(selectedSpotId);
        yield PromoCodeService.deletePromoCode(promoCodePk, token);
        const promoCodes = yield PromoCodeService.getPromoCodes(spotId, token);
        yield put({ type: DELETE_PROMO_CODE_SUCCESS, payload: { promoCodes: promoCodes }, selectedSpotID: spotId });
    } catch (error) {
        yield put({ type: DELETE_PROMO_CODE_FAILED, message: error.toString() || 'Could not delete discount code' });
    }
}

function* getVisitorsToValidate({ payload }: any) {
    try {
        const { spot } = payload;
        const token = yield select(tokenState);
        const visitors = yield ValidationRequestsService.getVisitorsToValidate(spot, token);
        yield put({ type: VISITORS_TO_VALIDATE_SUCCESS, payload: { visitors }, selectedSpotID: spot });
    } catch (error) {
        yield put({ type: VISITORS_TO_VALIDATE_FAILED, message: error.toString() || 'Could not fetch visitors' });
    }
}

function* addVisitorToValidate({ payload }: any) {
    try {
        const { spot, plate, validation_source, state, country } = payload;
        const token = yield select(tokenState);
        const data = { spot: spot, plate, state: state || null, country, validation_source };
        const newVisitor = yield VisitorService.addVisitor(data, token);
        const visitors = yield ValidationRequestsService.getVisitorsToValidate(spot, token);
        yield put({ type: ADD_VISITOR_TO_VALIDATE_SUCCESS, payload: { visitors, newVisitor }, selectedSpotID: spot });
    } catch (error) {
        yield put({
            type: ADD_VISITOR_TO_VALIDATE_FAILED,
            message: error.toString() || 'Could not add visitor',
        });
    }
}

function* validateVisitor({ payload }: any) {
    try {
        const { visitor_pk, minutes, spot, date, validation_source } = payload;
        const token = yield select(tokenState);
        const data = { visitor_pk: visitor_pk, minutes: minutes, validation_source };
        const { plate } = yield ValidationRequestsService.validateVisitor(data, token);
        const visitors = yield ValidationRequestsService.getVisitorsToValidate(spot, token);
        const validUntil = date ? `until ${date}` : `for ${minutes} minutes`;
        yield put({ type: VALIDATE_VISITOR_SUCCESS, payload: { visitors, plate, validUntil }, selectedSpotID: spot });
    } catch (error) {
        yield put({
            type: VALIDATE_VISITOR_FAILED,
            message: error.toString() || 'Could not validate visitor',
        });
    }
}

function* fetchSaga() {
    yield takeEvery(LOGIN_REQUESTED, loginProfile);
    yield takeEvery(SEND_VERIFICATION_CODE_REQUESTED, sendVerificationCode);
    yield takeEvery(PROFILE_REQUESTED, getProfile);
    yield takeEvery(SALES_REPS_REQUESTED, getSalesReps);
    yield takeEvery(ORGANIZATION_LIST_REQUESTED, getOrganizations);
    yield takeEvery(ORGANIZATION_REQUESTED, getOrganization);
    yield takeEvery(SPOT_LIST_REQUESTED, getSpotList);
    yield takeEvery(ORGANIZATION_SPOTS_REQUESTED, getSpots);
    yield takeEvery(SPOT_PRICING_REQUESTED, getSpotPricing);
    yield takeEvery(SPOT_MULTIDAY_PRICING_REQUESTED, getSpotMultidayPricing);
    yield takeEvery(ADD_SPOT_REQUESTED, addSpot);
    yield takeEvery(TEAM_REQUESTED, getTeam);
    yield takeEvery(ADD_TEAM_REQUESTED, addTeamMember);
    yield takeEvery(DELETE_TEAM_REQUESTED, deleteTeamMember);
    yield takeEvery(GET_TICKET_REQUESTED, getTicket);
    yield takeEvery(WAIVE_TICKET_REQUESTED, waiveTicket);
    yield takeEvery(REPORTS_REQUESTED, getReports);
    yield takeEvery(UPDATE_REPORTS_REQUESTED, updateReports);
    yield takeEvery(PROMO_CODE_REPORTS_REQUESTED, getPromoCodeReports);
    yield takeEvery(ENFORCEMENTS_REQUESTED, getEnforcements);
    yield takeEvery(CURRENTLY_PARKED_REQUESTED, getCurrentlyParked);
    yield takeEvery(CHECK_PLATE_REQUESTED, checkPlate);
    yield takeEvery(UPDATE_ENFORCEMENT_REQUESTED, updateEnforcement);
    yield takeEvery(ADD_ENFORCEMENT_PENALTY_REQUESTED, addEnforcementPenalty);
    yield takeEvery(DAILY_REPORTS_REQUESTED, getDailyReports);
    yield takeEvery(ADD_ORGANIZATION_REQUESTED, addOrganization);
    yield takeEvery(UPLOAD_SPOT_PHOTO_REQUESTED, uploadSpotPhoto);
    yield takeEvery(UPLOAD_LOGO_REQUESTED, uploadLogo);
    yield takeEvery(REFUNDS_REQUESTED, getRefunds);
    yield takeEvery(ISSUE_REFUND_REQUESTED, issueRefund);
    yield takeEvery(IGNORE_REFUND_REQUESTED, ignoreRefund);
    yield takeEvery(PROMO_CODES_REQUESTED, getPromoCodes);
    yield takeEvery(ADD_PROMO_CODE_REQUESTED, addPromoCode);
    yield takeEvery(DELETE_PROMO_CODE_REQUESTED, deletePromoCode);
    yield takeEvery(VISITORS_TO_VALIDATE_REQUESTED, getVisitorsToValidate);
    yield takeEvery(ADD_VISITOR_TO_VALIDATE_REQUESTED, addVisitorToValidate);
    yield takeEvery(VALIDATE_VISITOR_REQUESTED, validateVisitor);
    yield takeEvery(UPLOAD_VISITOR_CSV_REQUESTED, uploadVisitorsCSV);
    yield takeEvery(PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED, uploadVisitorsCSVProgress);
}

export default fetchSaga;
