import React, { useMemo, useContext, createContext, useCallback } from 'react';
import aggregatorReportReducer, { AggregatorReportState, initialAggregatorReportState } from './reducer';
import { AGGREGATOR_REPORTS_FAILED, AGGREGATOR_REPORTS_REQUESTED, AGGREGATOR_REPORTS_SUCCESS } from './constants';
import { reportClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';

interface AggregatorReportActions {
    getAggregatorReport: (monthYear: string, spotPk: number) => void;
}

interface AggregatorReportContextType extends AggregatorReportState, AggregatorReportActions {}

export const AggregatorReportContext = createContext<AggregatorReportContextType | null>(null);

export const AggregatorReportProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        aggregatorReportReducer,
        {
            ...initialAggregatorReportState,
        },
        [],
        [unauthorized]
    );

    const getAggregatorReport = useCallback(
        async (monthYear: string, spotPk: number) => {
            dispatch({ type: AGGREGATOR_REPORTS_REQUESTED });
            try {
                const [month, year] = monthYear.split(':');
                const aggregatorReports = await reportClient.getAggregatorReports(spotPk, { month, year });

                dispatch({
                    type: AGGREGATOR_REPORTS_SUCCESS,
                    payload: {
                        report: aggregatorReports,
                        timeKey: monthYear,
                    },
                });
            } catch (error) {
                dispatch({
                    type: AGGREGATOR_REPORTS_FAILED,
                    payload: {
                        error,
                        message: error?.toString() || 'Could not fetch reports',
                    },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<AggregatorReportContextType>(() => {
        return {
            ...state,
            getAggregatorReport,
        };
    }, [state, getAggregatorReport]);

    return <AggregatorReportContext.Provider value={value}>{children}</AggregatorReportContext.Provider>;
};

export const useAggregatorReport = () => {
    const context = useContext(AggregatorReportContext);

    if (!context) {
        throw new Error('Error: useAggregatorReport should be wrapped by AggregatorReportProvider.');
    }

    return context;
};
